import axios, { AxiosRequestConfig } from 'axios';
import { AUTHENTICATION_RESULTS } from '../constants/auth.constants';

const getApiUrl = () => {
  const envName = process.env.NODE_ENV;
  const apiUrl = process.env.REACT_APP_API_HOST;

  if (envName && !['production', 'test'].includes(envName)) {
    // eslint-disable-next-line
    console.warn(
      `App is running under ${envName} environment config:\n\tAPI: ${apiUrl}`
    );
  }

  return apiUrl;
};

const client = axios.create({
  baseURL: getApiUrl(),
});

axios.defaults.headers.common['Content-Type'] = 'application/json';
const requestInterceptor = async (req: AxiosRequestConfig) => {
  req.headers!['Correlation-Object'] = JSON.stringify({
    correlationId: crypto.randomUUID(),
  });

  const authResults = sessionStorage.getItem(AUTHENTICATION_RESULTS);
  if (authResults) {
    const idToken = JSON.parse(authResults).IdToken;
    if (idToken) req.headers!.Authorization = idToken;
  }

  return req;
};

client.interceptors.request.use(requestInterceptor);

export default client;
