export interface FeaturedStoryDetails {
  title: string;
  href: string;
  imageUrl: string;
  alt?: string;
}

export const featuredStoriesArray: FeaturedStoryDetails[] = [
  {
    title: 'Fortune® 100 Best Companies to Work For® 2022',
    href: 'https://www.slalom.com/newsroom/slalom-2022-fortune-100-best-companies',
    imageUrl: '/featured-stories-assets/FS1.png',
  },
  {
    title: 'Best Workplaces® for  Millennials™ 2022',
    href: 'https://www.slalom.com/newsroom/best-workplaces-for-millennials-2022',
    imageUrl: '/featured-stories-assets/FS2.png',
  },
  {
    title: 'Fortune® 100 Best Companies to Work For® 2022',
    href: 'https://www.slalom.com/newsroom/slalom-2022-fortune-100-best-companies',
    imageUrl: '/featured-stories-assets/FS1.png',
  },
  {
    title: 'Best Workplaces® for  Millennials™ 2022',
    href: 'https://www.slalom.com/newsroom/best-workplaces-for-millennials-2022',
    imageUrl: '/featured-stories-assets/FS2.png',
  },
];
