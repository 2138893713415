import axios, { AxiosRequestConfig } from 'axios';
import { EventCardDetails } from 'components/event-cards/event-card-details';
import { format } from 'date-fns';
import { AUTHENTICATION_RESULTS } from '../constants/auth.constants';
import { ContentfulEventRawResponse } from '../interfaces/ContentfulResponse.interface';

const getContentfulApiUrl = () => {
  const envName = process.env.NODE_ENV;
  const contentfulApiUrl = process.env.REACT_APP_CONTENTFUL_HOST;

  if (envName && !['production', 'test'].includes(envName)) {
    // eslint-disable-next-line
    console.warn(
      `App is running under ${envName} environment config:\n\tAPI: ${contentfulApiUrl}`
    );
  }

  return contentfulApiUrl;
};

const client = axios.create({
  baseURL: getContentfulApiUrl(),
});

axios.defaults.headers.common['Content-Type'] = 'application/json';
const requestInterceptor = async (req: AxiosRequestConfig) => {
  req.headers!['Correlation-Object'] = JSON.stringify({
    correlationId: crypto.randomUUID(),
  });

  const authResults = sessionStorage.getItem(AUTHENTICATION_RESULTS);
  if (authResults) {
    const accessToken = JSON.parse(authResults).AccessToken;
    if (accessToken) req.headers!.Authorization = accessToken;
  }
  return req;
};

client.interceptors.request.use(requestInterceptor);

const getDate = (startDate: Date): string => format(startDate, 'MMM d');

const getTime = (startDate: Date, endDate: Date): string =>
  `${format(
    startDate,
    `h${startDate.getMinutes() === 0 ? '' : ':m'}${
      format(startDate, 'aa') === format(endDate, 'aa') ? '' : ' aa'
    }`
  )}-${format(endDate, `h${endDate.getMinutes() === 0 ? '' : ':m '} aa`)}`;

function processEvents(rawEventCollection: any) {
  const events = rawEventCollection.eventCollection.items;
  const eventCards = events.map((event: any) => {
    const startDate = new Date(event.startDate);
    const endDate = new Date(event.endDate);
    const eventCard: EventCardDetails = {
      title: event.title,
      subtitle:
        event.description === null
          ? ''
          : JSON.parse(event.description.json).content[0].content[0].value,
      eligibleParticipants: event.eligibleParticipant,
      date: getDate(startDate),
      time: getTime(startDate, endDate),
      location: event.location,
      imageUrl:
        event.bannerImage === null
          ? '/event-assets/build_logo.png'
          : event.bannerImage.url,
    };
    return eventCard;
  });
  return eventCards;
}

export const getEvents = async (query: string): Promise<EventCardDetails[]> => {
  try {
    const response = await client.post<ContentfulEventRawResponse>('', {
      query,
    });
    const rawEventCollection = JSON.parse(response.request?.response).data;
    const result = processEvents(rawEventCollection);
    return result;
  } catch (error) {
    throw new Error(
      `An error occured when getting/parsing events from contentful get appsync: ${error}`
    );
  }
};

export default { getEvents };
