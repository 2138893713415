import CheckIcon from '@mui/icons-material/Check';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import info from '../../assets/info.svg';
import {
  slalomCapabilities,
  slalomGlobalTeams,
  SlalomMarket,
  slalomMarkets,
  UserData,
} from '../../registration-context/userConstants';
import UserRegistrationFormContext from '../../registration-context/UserRegistrationFormContext';
import { REGISTRATION, REGISTRATION_PROFILE_INFO } from '../../routeConstants';
import MultiSelect from '../common/multi-select/multi-select';
import { OutlinedButton } from '../common/outlined-button/outlined-button';
import SlalomTooltip from '../common/slalom-tooltip/slalom-tooltip';
import { SolidButton } from '../common/solid-button/solid-button';
import './registration-slalom-info.scss';
import SlalomInfoDropdown from './slalom-info-dropdown';

const RegistrationSlalomInfo: FC = function () {
  const { userData: state, updateUserData: dispatch } = useContext(
    UserRegistrationFormContext
  );
  const [globalTeam, setGlobalTeam] = useState('');
  const [homeMarket, setHomeMarket] = useState('');
  const [capability, setCapability] = useState('');
  const [userData, setUserData] = useState<UserData>(state);
  const [acknowledged, setAcknowledged] = useState(false);
  const infoAcknowledgement =
    'I understand Slalom will use this information ONLY ' +
    'to verify this account as legitimate and that no details around my employment other than verification will be used.*';

  useEffect(() => {
    setGlobalTeam(userData.slalomGlobalTeam);
    setHomeMarket(userData.homeMarket);
    setCapability(userData.slalomCapability);
  }, []);

  const handleChange =
    (prop: keyof UserData) => (event: ChangeEvent<HTMLInputElement>) => {
      setUserData({ ...userData, [prop]: event.target.value });
    };

  const handleCheck = () => {
    setAcknowledged(!acknowledged);
  };

  const marketInterestsTooltipText: string =
    'Choose all markets you hope to get upcoming event and other information from';

  const navigate: NavigateFunction = useNavigate();

  const navigateToRegistrationProfileInfo = () => {
    navigate(`${REGISTRATION}${REGISTRATION_PROFILE_INFO}`);
  };

  const navigateBackOne = () => {
    navigate(-1);
  };

  const chipDelete = () => {
    const copy = userData.marketInterests;
    copy?.splice(0, 1);
    setUserData({ ...userData, marketInterests: copy });
  };

  const handleMarketInterestChange = (
    event: SelectChangeEvent<SlalomMarket[]>
  ) => {
    const {
      target: { value },
    } = event;
    if (typeof value === 'string') {
      setUserData({ ...userData, marketInterests: [value] });
    } else {
      setUserData({ ...userData, marketInterests: value });
    }
  };

  const handleNext = () => {
    dispatch({
      ...userData,
      homeMarket,
      slalomGlobalTeam: globalTeam,
      slalomCapability: capability,
    });
    navigateToRegistrationProfileInfo();
  };

  const enableButton: boolean =
    globalTeam !== '' &&
    homeMarket !== '' &&
    userData.marketInterests.length > 0 &&
    capability !== '' &&
    (userData.prevPeopleManagerName !== '' ||
      state.prevPeopleManagerName !== '') &&
    acknowledged;

  return (
    <div className="registration-info">
      <section className="info-box">
        <span className="page-number">Step 2 of 4</span>
        <div className="header-text">
          <h3 className="title">Tell us more about your Slalom information</h3>
          <p className="description">
            We’ll use this information to verify your account. Once verified,
            you’ll receive an email with further instructions on how to access
            the platform and your profile.
          </p>
        </div>

        <SlalomInfoDropdown
          inputTitle="Slalom global team*"
          options={slalomGlobalTeams}
          formItemValue={globalTeam}
          setFormItemValue={setGlobalTeam}
        />

        <SlalomInfoDropdown
          inputTitle="Home market*"
          options={slalomMarkets}
          formItemValue={homeMarket}
          setFormItemValue={setHomeMarket}
        />

        <div className="multi-select-container">
          <MultiSelect
            className="market-interest-select"
            onChange={handleMarketInterestChange}
            options={slalomMarkets}
            onDelete={chipDelete}
            labelText="Markets of interest*"
            userDataArray={userData.marketInterests ?? []}
          />
          <SlalomTooltip
            className="market-interest-tooltip"
            title={marketInterestsTooltipText}
            placement="right"
          >
            <img className="info-icon" src={info} alt="" />
          </SlalomTooltip>
        </div>

        <SlalomInfoDropdown
          inputTitle="Capability*"
          options={slalomCapabilities}
          formItemValue={capability}
          setFormItemValue={setCapability}
        />

        <div className="titleContainer">
          <TextField
            className={`input-field input ${
              userData.slalomJobTitle ? 'has-input' : ''
            }`}
            value={userData.slalomJobTitle}
            label="Job Title"
            aria-label="job title"
            onChange={handleChange('slalomJobTitle')}
          />
        </div>

        <Stack className="container">
          <TextField
            className={`large-input-field input ${
              userData.prevPeopleManagerName ? 'has-input' : ''
            }`}
            value={userData.prevPeopleManagerName}
            label="Most recent people leader's name"
            aria-label="Leader"
            required
            onChange={handleChange('prevPeopleManagerName')}
          />

          <TextField
            className={`large-input-field input ${
              userData.prevSlalomEmail ? 'has-input' : ''
            }`}
            value={userData.prevSlalomEmail}
            label="Former slalom email"
            aria-label="FormerSlalomEmail"
            onChange={handleChange('prevSlalomEmail')}
          />
        </Stack>

        <FormGroup className="acknowledgement-form">
          <FormControlLabel
            className="acknowledgement-container"
            control={
              <div className="checkbox" data-testid="checkbox">
                <Checkbox checkedIcon={<CheckIcon />} />
              </div>
            }
            label={infoAcknowledgement}
            onChange={handleCheck}
          />
        </FormGroup>

        <div className="button-container">
          <OutlinedButton
            className="back-button"
            theme="outlined_dark"
            onClick={() => {
              navigateBackOne();
              dispatch(userData);
            }}
            rightIconType="left-caret"
            isIconOnly
          />
          <SolidButton
            className="next-button"
            buttonText="Next"
            onClick={handleNext}
            disabled={!enableButton}
            rightIconType="right-caret"
            isWhiteBackground
          />
        </div>
      </section>
    </div>
  );
};

export default RegistrationSlalomInfo;
