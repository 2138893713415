import { Route, Routes } from 'react-router-dom';
import RegistrationBasicInfo from '../../components/registration-basic-info/registration-basic-info';
import RegistrationComplete from '../../components/registration-complete/registration-complete';
import RegistrationLanding from '../../components/registration-landing/registration-landing';
import RegistrationNotificationInfo from '../../components/registration-notification-info/registration-notification-info';
import RegistrationProfileInfo from '../../components/registration-profile-info/registration-profile-info';
import RegistrationSlalomInfo from '../../components/registration-slalom-info/registration-slalom-info';
import RegistrationSuccessful from '../../components/registration-successful/registration-successful';
import RegistrationUnsuccessful from '../../components/registration-unsuccessful/registration-unsuccessful';
import UserRegistrationFormProvider from '../../registration-context/UserRegistrationFormProvider';
import * as RouteConstants from '../../routeConstants';
import './registration-page.scss';

const RegistrationPage: React.FC = function () {
  return (
    <div className="registration-base">
      <UserRegistrationFormProvider>
        <Routes>
          <Route path="" element={<RegistrationLanding />} />
          <Route
            path={RouteConstants.REGISTRATION_BASIC_INFO}
            element={<RegistrationBasicInfo />}
          />
          <Route
            path={RouteConstants.REGISTRATION_SLALOM_INFO}
            element={<RegistrationSlalomInfo />}
          />
          <Route
            path={RouteConstants.REGISTRATION_PROFILE_INFO}
            element={<RegistrationProfileInfo />}
          />
          <Route
            path={RouteConstants.REGISTRATION_NOTIFICATION_INFO}
            element={<RegistrationNotificationInfo />}
          />
          <Route
            path={RouteConstants.REGISTRATION_COMPLETE}
            element={<RegistrationComplete />}
          />
          <Route
            path={RouteConstants.REGISTRATION_SUCCESSFUL}
            element={<RegistrationSuccessful />}
          />
          <Route
            path={RouteConstants.REGISTRATION_UNSUCCESSFUL}
            element={<RegistrationUnsuccessful />}
          />
        </Routes>
      </UserRegistrationFormProvider>
    </div>
  );
};

export default RegistrationPage;
