import { FC } from 'react';
import ArticlePreview from './article-preview';
import { ArticlePreviewDetails } from './article-preview-array';
import './article-preview-list.scss';

interface ArticlePreviewListProps {
  articlePreviewArray: ArticlePreviewDetails[];
}

const ArticlePreviewList: FC<ArticlePreviewListProps> = function ({
  articlePreviewArray,
}: ArticlePreviewListProps) {
  const articlePreviewList = () =>
    articlePreviewArray.map((articlePreview, index) => (
      <ArticlePreview
        article={articlePreview}
        imageOnLeft={index % 2 === 0}
        key={articlePreview.title}
      />
    ));

  return (
    <section className="article-preview-section">
      <div className="article-preview-list" data-testid="article-preview-list">
        {articlePreviewList()}
      </div>
    </section>
  );
};

export default ArticlePreviewList;
