import Carousel from 'react-material-ui-carousel';
import { FeaturedStoryDetails } from './featured-stories-array';
import FeaturedStory from './featured-story';
import './featured-stories.scss';

interface FeaturedStoriesProps {
  stories: FeaturedStoryDetails[];
}

const FeaturedStories: React.FC<FeaturedStoriesProps> = function ({
  stories,
}: FeaturedStoriesProps) {
  const featuredStory = () =>
    stories.map((story) => <FeaturedStory story={story} key={story.title} />);
  const indicatorIcon = () => <div />;

  return (
    <Carousel
      className="featured-stories-carousel"
      interval={8000}
      duration={700}
      IndicatorIcon={indicatorIcon()}
      indicatorIconButtonProps={{
        className: 'indicator-icon',
      }}
      activeIndicatorIconButtonProps={{
        className: 'indicator-icon-active',
      }}
      indicatorContainerProps={{
        className: 'indicator-icon-container',
      }}
      animation="slide"
      navButtonsAlwaysInvisible
    >
      {featuredStory()}
    </Carousel>
  );
};

export default FeaturedStories;
