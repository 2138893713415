export interface VideoDetails {
  title: string;
  date: string;
  videoUrl: string;
  youtubeUrl: string;
  timestamp: string;
}

export const videosArray: VideoDetails[] = [
  {
    title: '_buildher: Interviewing at Build',
    date: 'Sep 25, 2020',
    videoUrl: '/video-assets/V2.png',
    youtubeUrl: 'https://www.youtube.com/watch?v=50R0sL2_t38',
    timestamp: '4:04',
  },
  {
    title: '_buildher: How I Became an XD Intern',
    date: 'Sep 2, 2020',
    videoUrl: '/video-assets/V3.png',
    youtubeUrl: 'https://www.youtube.com/watch?v=cS__qt6puB0',
    timestamp: '3:03',
  },
  {
    title: '_buildher: A Day in the Life of an SO Intern',
    date: 'Aug 21, 2020',
    videoUrl: '/video-assets/V4.png',
    youtubeUrl: 'https://www.youtube.com/watch?v=LmI7bbe8nVI',
    timestamp: '2:58',
  },
];

export const mainVideo: VideoDetails[] = [
  {
    title: 'Life at Slalom Build: Build Your Best Life',
    date: 'Sep 19, 2018',
    videoUrl: '',
    youtubeUrl: 'https://www.youtube.com/watch?v=Oaz_ujsVyTc',
    timestamp: '4:14',
  },
];
