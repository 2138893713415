import { FormControl, TextField } from '@mui/material';
import { ChangeEvent, FunctionComponent } from 'react';
import { UserData } from '../../../registration-context/userConstants';
import './name.scss';

interface NameProps {
  firstName: string;
  lastName: string;
  preferredName: string;
  onChange: (
    prop: keyof UserData
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
}

const Name: FunctionComponent<NameProps> = function ({
  firstName,
  lastName,
  preferredName,
  onChange,
}) {
  return (
    <div className="legal-names">
      <FormControl fullWidth className="name-field">
        <TextField
          className={`input-field input ${firstName && 'has-input'}`}
          inputProps={{ maxLength: 36 }}
          value={firstName}
          label="Legal first name"
          required
          onChange={onChange('firstName')}
        />
      </FormControl>
      <FormControl fullWidth className="name-field">
        <TextField
          className={`input-field input ${lastName && 'has-input'}`}
          inputProps={{ maxLength: 36 }}
          value={lastName}
          label="Legal last name"
          required
          onChange={onChange('lastName')}
        />
      </FormControl>
      <FormControl fullWidth className="name-field">
        <TextField
          className={`input-field input ${preferredName && 'has-input'}`}
          value={preferredName}
          label="Preferred first name"
          onChange={onChange('preferredName')}
        />
      </FormControl>
    </div>
  );
};

export default Name;
