import { FC } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import video from '../../assets/homepage-hero-v8.mp4';
import { LANDING_PAGE } from '../../routeConstants';
import { OutlinedButton } from '../common/outlined-button/outlined-button';
import './registration-complete.scss';

const RegistrationComplete: FC = function () {
  const navigate: NavigateFunction = useNavigate();
  const navigateToHome = () => {
    navigate(LANDING_PAGE);
  };

  return (
    <div className="registration-complete">
      <video autoPlay muted loop className="registration-hero-video">
        <source src={video} type="video/mp4" />
      </video>
      <section className="registration-complete-message">
        <h3>Application received. Stay tuned!</h3>
        <p>
          We will verify your account shortly and grant you access to our portal
          where you will be able to do all kinds of cool stuff.
        </p>
        <p>
          Once you are approved, an email will be sent. Thank you for your
          patience.
        </p>
        <OutlinedButton
          className="return-home-button"
          buttonText="Return to Home"
          theme="outlined_dark"
          onClick={navigateToHome}
        />
      </section>
    </div>
  );
};

export default RegistrationComplete;
