import { FC } from 'react';
import './contact-info-section.scss';
import {
  ContactInfoCard,
  ContactInfoCardProps,
} from '../contact-info-card/contact-info-card';
import '../../../css/breakpoint-layout.scss';

interface ContactInfoSectionProps {
  contactInfo: ContactInfoCardProps[];
}

// returns the contact information section
export const ContactInfoSection: FC<ContactInfoSectionProps> = function ({
  contactInfo,
}) {
  // maps the values set in contactInfoSectionArray to the contact info card
  const renderContactInfo = () =>
    contactInfo.map(({ icon, title, text, contactLink, href }) => (
      <ContactInfoCard
        key={title}
        icon={icon}
        title={title}
        text={text}
        contactLink={contactLink}
        href={href}
      />
    ));

  return <section className="card-container">{renderContactInfo()}</section>;
};

export default ContactInfoSection;
