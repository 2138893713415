import { Avatar, Badge, ContainerProps, styled } from '@mui/material';
import { useContext } from 'react';
import UserRegistrationFormContext from '../../registration-context/UserRegistrationFormContext';
import Icon from '../common/icons/icon';
import './profile-summary.scss';

const SmallAvatar = styled(Avatar)(() => ({
  width: 30,
  height: 30,
}));

const ProfileImg = styled(Avatar)(() => ({
  width: 129,
  height: 129,
}));

const ProfileSummary: React.FC<ContainerProps> = function () {
  const { userData: state } = useContext(UserRegistrationFormContext);
  /** TODO: ALUM-178 add logic to update profile picture with uploaded image */
  // const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files != null) {
  //     dispatch({ image: e.target.value });
  //   }
  // };
  /** TODO: ALUM-178 when profile picture is clicked open file handeler */
  // const uploadProfileImage = () => {
  //   const input = document.getElementById('image');
  //   input?.click();
  // };
  return (
    <div className="profile-summary">
      {/* TODO: ALUM-178 add onClick={uploadProfileImage} and onKeyDown={uploadProfileImage}  */}
      <div className="profile-image" role="button" tabIndex={0}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <SmallAvatar>
              <Icon iconName="edit" />
            </SmallAvatar>
          }
        >
          <ProfileImg src={state.image} />
        </Badge>
      </div>
      <div className="profile-info">
        <h4 className="fullName" data-testid="fullName">
          {`${state.preferredName ?? state.firstName} ${state.lastName}`}
        </h4>
        <p className="tagline" data-testid="tagline">
          At <i>Slalom {state.slalomGlobalTeam}</i> based out of the{' '}
          {state.homeMarket} office
        </p>
        <p className="caption capability" data-testid="capability">
          {state.slalomCapability}
        </p>
      </div>
      {/* TODO: ALUM-178 add onChange = {handleChange} */}
      <input id="image" type="file" accept="image/*" hidden />
    </div>
  );
};

export default ProfileSummary;
