import { useEffect, useState } from 'react';
import OutlinedButton from '../common/outlined-button/outlined-button';
import EventCard from './event-card';
import { EventCardDetails } from './event-card-details';
import './event-card-list.scss';

interface EventCardListProps {
  eventArray: EventCardDetails[];
}

const EventCardList: React.FC<EventCardListProps> = function ({ eventArray }) {
  const cardsToShowPerPage = 6;
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(cardsToShowPerPage);
  const [showNext, setShowNext] = useState(
    eventArray.length > cardsToShowPerPage
  );
  const [showBack, setShowBack] = useState(false);

  const checkState = () => {
    if (!startIndex && endIndex === cardsToShowPerPage) {
      setShowBack(false);
      setShowNext(true);
    }
    if (startIndex && endIndex >= eventArray.length - 1) {
      setShowBack(true);
      setShowNext(false);
    }
    if (startIndex && endIndex < eventArray.length) {
      setShowBack(true);
      setShowNext(true);
    }
  };

  const nextPage = () => {
    setStartIndex(startIndex + cardsToShowPerPage);
    setEndIndex(endIndex + cardsToShowPerPage);
  };

  const backPage = () => {
    setStartIndex(startIndex - cardsToShowPerPage);
    setEndIndex(endIndex - cardsToShowPerPage);
  };

  useEffect(() => {
    checkState();
  });

  const cardList = () =>
    eventArray
      .slice(startIndex, endIndex)
      .map((event) => <EventCard event={event} key={event.title} />);

  return (
    <section className="event-grid">
      <div className="title-bar">
        <h3>Upcoming Events</h3>
        <div className="button-container">
          {showBack && (
            <OutlinedButton
              onClick={backPage}
              isIconOnly
              rightIconType="left-caret"
              theme="outlined_dark"
              className="button left"
            />
          )}
          {showNext && (
            <OutlinedButton
              onClick={nextPage}
              isIconOnly
              rightIconType="right-caret"
              theme="outlined_dark"
              className="button right"
            />
          )}
        </div>
      </div>

      <div className="events-list" data-testid="events-list">
        {cardList()}
      </div>
    </section>
  );
};

export default EventCardList;
