import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/common/helper-fcns/helper';
import Footer from './components/footer/footer';
import Header from './components/header/Header';
import ProtectedRoute from './components/protected-route/protected-route';
import { AuthProvider } from './contexts/auth.context';
import AdminPage from './pages/admin-page/admin-page';
import AlumniPage from './pages/alumni-page.tsx/alumni-page';
import LandingPage from './pages/landing-page/landing-page';
import LoginPage from './pages/login-page/login-page';
import RegistrationPage from './pages/registration-page/registration-page';
import * as RouteConstants from './routeConstants';

const Routing = function () {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Header />
        <div className="nav-menu">
          <Routes>
            <Route
              path={RouteConstants.LANDING_PAGE}
              element={<LandingPage />}
            />
            {/* TODO: change to routing to admin login then going to dashboard (TAKEN FROM ALUM-101) */}
            <Route element={<ProtectedRoute />}>
              <Route
                path={RouteConstants.ALUMNI_LANDING}
                element={<AlumniPage />}
              />
            </Route>

            <Route
              path={RouteConstants.REGISTRATION_LANDING}
              element={<RegistrationPage />}
            />

            <Route element={<ProtectedRoute />}>
              <Route
                path={RouteConstants.ADMIN_LANDING}
                element={<AdminPage />}
              />
            </Route>

            <Route
              path={RouteConstants.LOGIN_LANDING}
              element={<LoginPage />}
            />

            <Route path={RouteConstants.ADMIN_LOGIN} element={<LoginPage />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default Routing;
