import UserRole from 'enums/userRole.enums';
import { createContext } from 'react';
import useAuthProvider from '../hooks/useAuthProvider';

export interface AuthServices {
  loginUser: (
    email: string,
    password: string,
    userRole?: UserRole
  ) => Promise<boolean>;
  resetPassword: (
    email: string,
    password: string,
    challengeName: string,
    sessionId: string
  ) => Promise<boolean>;
  isAuthenticated: boolean;
  updateIsAuthenticated: () => void;
  logoutUser: () => void;
  isResetRequired: () => boolean;
  role: string;
}

export const AuthContext = createContext<AuthServices>({
  isAuthenticated: false,
} as AuthServices);

export const AuthProvider = function ({ children }: any) {
  const auth = useAuthProvider();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
