import { FunFactItem } from '../fun-facts/fun-facts';

const testFacts: FunFactItem[] = [
  {
    value: '44',
    title: 'Benefits added since 2021',
  },
  {
    value: '2312312%',
    title: 'YOY Slalom Revenue Growth',
  },
  {
    value: '230%',
    title: 'YOY Employee Growth',
  },
  {
    value: '12',
    title: 'Countries of Alumni',
  },
];

export default testFacts;
