import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { Avatar, styled } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import * as RouteConstants from '../../routeConstants';
import { getAttributeFromToken } from '../../services/auth.service';
import { OutlinedButton } from '../common/outlined-button/outlined-button';
import {
  SolidButton,
  SolidButtonTheme,
} from '../common/solid-button/solid-button';
import { useComponentVisible, useWindowDimensions } from './header-helpers';
import './Header.scss';

const SmallAvatar = styled(Avatar)(() => ({
  width: 24,
  height: 24,
}));

interface ViewProp {
  view: string;
}

const NavHeader: React.FC<ViewProp> = function ({ view }: ViewProp) {
  const navigate: NavigateFunction = useNavigate();
  const windowDimensions = useWindowDimensions();
  const { ref, setOpen, open } = useComponentVisible();
  const { logoutUser } = useAuth();

  const login = () => {
    navigate(RouteConstants.LOGIN);
  };
  const logout = () => {
    logoutUser();
    setOpen(false);
    navigate(RouteConstants.LANDING_PAGE);
  };
  const contentManagement = () => {
    // navigates to Content Management System
  };
  const adminDashboard = () => {
    navigate(RouteConstants.ADMIN + RouteConstants.ADMIN_DASHBOARD);
  };

  const isRegistration: boolean = window.location.href.includes(
    RouteConstants.REGISTRATION
  );

  const isAdminDashboard: boolean = window.location.pathname.includes(
    RouteConstants.ADMIN_DASHBOARD
  );

  // logged In array
  const loggedInButtons: {
    className: string;
    buttonText: string;
    onClickHandler: () => void;
    buttonTheme: SolidButtonTheme;
  }[] = [
    {
      className: 'blogsAndArticles '.concat(
        window.location.href.indexOf('blogs-and-articles') > 0 ? 'bold' : ''
      ),
      buttonText: 'Blogs & Articles',
      onClickHandler: () =>
        navigate(RouteConstants.ALUMNI + RouteConstants.BLOGS_AND_ARTICLES),
      buttonTheme: 'text-only',
    },
    {
      className: 'videos',
      buttonText: 'Videos',
      onClickHandler: () =>
        navigate(RouteConstants.ALUMNI + RouteConstants.ALUMNI_VIDEOS),
      buttonTheme: 'text-only',
    },
    {
      className: 'careersAndReferrals',
      buttonText: 'Careers & Referrals',
      onClickHandler: () => 'navigates to careers and referrals',
      buttonTheme: 'text-only',
    },
  ];

  const loggedInButtonsFcn = () =>
    loggedInButtons.map((buttonItem) => (
      <SolidButton
        className={buttonItem.className}
        buttonText={buttonItem.buttonText}
        key={buttonItem.buttonText}
        onClick={() => buttonItem.onClickHandler()}
        buttonTheme={buttonItem.buttonTheme}
      />
    ));

  // logged In array for hamburger menu
  const loggedInHamburgerList: {
    className: string;
    buttonText: string;
    onClickHandler: () => void;
    buttonTheme: SolidButtonTheme;
  }[] = [
    {
      className: 'myProfile',
      buttonText: 'My Profile',
      onClickHandler: () => 'navigates to myProfile',
      buttonTheme: 'text-only',
    },
    {
      className: 'Settings',
      buttonText: 'Settings',
      onClickHandler: () => 'navigates to settings',
      buttonTheme: 'text-only',
    },
    {
      className: 'logout',
      buttonText: 'Log out',
      onClickHandler: logout,
      buttonTheme: 'text-only',
    },
  ];

  const loggedInHamburgerListFcn = () =>
    loggedInHamburgerList.map((buttonItem) => (
      <SolidButton
        className={buttonItem.className}
        buttonText={buttonItem.buttonText}
        key={buttonItem.buttonText}
        onClick={() => buttonItem.onClickHandler()}
        buttonTheme={buttonItem.buttonTheme}
      />
    ));

  const handleArrowDropdown = () => {
    setOpen(!open);
  };

  const renderRegistrationView = () => (
    <>
      <li className="registerLinkItem" aria-label="registerLink">
        <SolidButton
          className={`${isRegistration && 'bold'}`}
          buttonText="Register"
          onClick={() => navigate(RouteConstants.REGISTRATION)}
          buttonTheme="text-only"
        />
      </li>
      <li className="loginButtonItem" aria-label="loginButton">
        <OutlinedButton
          buttonText="Log in"
          theme="outlined_light"
          onClick={login}
        />
      </li>
      <li className="loginTextItem" aria-label="loginLink">
        <SolidButton
          buttonText="Log in"
          onClick={login}
          buttonTheme="text-only"
        />
      </li>
    </>
  );

  const renderLoggedInView = () => (
    <>
      <li className="loggedInHeaderLinks" key="logged-in-links">
        {windowDimensions > 1020 && (
          <>
            <section
              data-testid="logged-in-header-links"
              className="alumNavbar"
            >
              {loggedInButtonsFcn()}
            </section>

            <section className="alumLinksContainer" ref={ref}>
              <div
                className={open ? 'dropdown-fullbar open' : 'dropdown-fullbar'}
              >
                {loggedInHamburgerListFcn()}
              </div>

              <span> | </span>

              {/* icon goes here  */}
              <SmallAvatar
                onClick={handleArrowDropdown}
                className="profileImage"
              />

              <div
                role="button"
                tabIndex={0}
                className={
                  open ? 'navigation-fullbar open' : `navigation-fullbar`
                }
                onClick={handleArrowDropdown}
                onKeyDown={handleArrowDropdown}
              >
                <span>{getAttributeFromToken('email')}</span>
                <KeyboardArrowDownRounded />
              </div>
            </section>
          </>
        )}
      </li>

      <li className="loggedInHamburgerLinks" key="logged-in-hamburger-links">
        {windowDimensions < 1020 && (
          <>
            {loggedInButtonsFcn()}
            {loggedInHamburgerListFcn()}
          </>
        )}
      </li>
    </>
  );

  const renderAdminView = () => (
    <>
      <li
        className="cms bar-item"
        aria-label="content-management-system"
        key="cms"
      >
        <SolidButton
          buttonText="Content Management System"
          onClick={contentManagement}
          buttonTheme="text-only"
        />
      </li>
      <li className="dashboard bar-item" aria-label="dashboard" key="dashboard">
        <SolidButton
          className={`${isAdminDashboard && 'bold'}`}
          buttonText="Dashboard"
          buttonTheme="text-only"
          onClick={adminDashboard}
        />
      </li>
      <li className="logout bar-item" aria-label="logout" key="logout">
        <OutlinedButton
          buttonText="Log out"
          onClick={logout}
          theme="outlined_light"
        />
      </li>
      <li className="logout-solid" aria-label="logout" key="logout-hamburger">
        <SolidButton
          buttonText="Log out"
          onClick={logout}
          buttonTheme="text-only"
        />
      </li>
    </>
  );

  const switchRender = () => {
    switch (view) {
      case 'isLoggedIn':
        return renderLoggedInView();
      case 'isAdmin':
        return renderAdminView();
      default:
        return renderRegistrationView();
    }
  };

  return <>{switchRender()}</>;
};

export default NavHeader;
