import { Link } from 'react-router-dom';
import { ADMIN_LOGIN } from '../../routeConstants';

interface FooterLinksProps {
  linkConfigs: FooterLinksConfig[];
}
export interface FooterLinksConfig {
  href: string;
  text: string;
  testId: string;
}

const FooterLinks: React.FC<FooterLinksProps> = function ({
  linkConfigs,
}: FooterLinksProps) {
  function linkMapper() {
    return linkConfigs.map((item: FooterLinksConfig): JSX.Element => {
      if (item.text === 'Admin log in') {
        return (
          <Link to={ADMIN_LOGIN} key={item.href} className="link">
            {item.text}
          </Link>
        );
      }
      return (
        <a
          key={item.href}
          data-testid={item.testId}
          href={item.href}
          className="link"
          target="_blank"
          rel="noreferrer noopener"
        >
          {item.text}
        </a>
      );
    });
  }
  return <nav className="links">{linkMapper()}</nav>;
};

export default FooterLinks;
