export const slalomMarkets = [
  'Atlanta',
  'Boston',
  'Charlotte',
  'Chicago',
  'Denver',
  'Houston',
  'Los Angeles',
  'Seattle',
  'Toronto',
  'Vancouver',
];

export type SlalomMarket = typeof slalomMarkets[number];

export const slalomCapabilities = [
  'Software Engineering',
  'Data Engineering',
  'Quality Engineering',
  'Solution Ownership',
  'Experience Design',
  'Platform Engineering',
];

export type SlalomCapability = typeof slalomCapabilities[number];

export const interests = [
  'Software Engineering',
  'Data Engineering',
  'Quality Engineering',
  'Solution Ownership',
  'UX',
  'Platform Engineering',
];

export type Interest = typeof interests[number];

export const slalomGlobalTeams = ['Build', 'Local', 'Emerge'];

export type SlalomGlobalTeam = typeof slalomGlobalTeams[number];

export const accountStatuses = [
  'active',
  'inactive',
  'awaiting_approval',
  'rejected',
  'revoked',
];

export type AccountStatus = typeof accountStatuses[number];

export const emailRegExp: RegExp = /^\S+@\S+\.\S+$/;
export interface UserData {
  email: string;
  sort_key: string;
  image?: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  pronouns?: string;
  preferredName?: string;
  homeMarket: SlalomMarket;
  marketInterests: SlalomMarket[];
  slalomGlobalTeam: SlalomGlobalTeam;
  slalomJobTitle?: string;
  slalomCapability: SlalomCapability;
  prevPeopleManagerName: string;
  prevSlalomEmail?: string;
  currEmployer?: string;
  currEmployerVisible: boolean;
  currPosition?: string;
  currPositionVisible: boolean;
  linkedInUrl?: string;
  linkedInUrlVisible: boolean;
  websiteUrl?: string;
  websiteUrlVisible: boolean;
  biography?: string;
  biographyVisible: boolean;
  interests?: Interest[];
  interestsVisible: boolean;
  isEnrolledNotifications: boolean;
  isEmailExposedAlums: boolean;
  isTermsConditionsAccepted: boolean;
  accountStatus?: AccountStatus;
  rejectReason?: string;
}

export interface UserResponse {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  pronouns: string;
  preferredName: string;
  homeMarket: string;
  marketInterests: string[];
  slalomGlobalTeam: SlalomGlobalTeam;
  slalomJobTitle: string;
  slalomCapability: string;
  prevPeopleManagerName: string;
  prevSlalomEmail: string;
  currEmployerVisible: boolean;
  currPositionVisible: boolean;
  linkedInUriVisible: boolean;
  websiteUrlVisible: boolean;
  biographyVisible: boolean;
  interestsVisible: boolean;
  isEnrolledNotifications: boolean;
  isEmailExposedAlums: boolean;
  isTermsConditonsAccepted: boolean;
  accountStatuses: AccountStatus;
  createdTime: string;
  updatedTime: string;
}
