import MenuIcon from '@mui/icons-material/Menu';
import useAuth from 'hooks/useAuth';
import { NavLink } from 'react-router-dom';
import * as RouteConstants from '../../routeConstants';
import logo from './build-logo.svg';
import { useComponentVisible } from './header-helpers';
import './Header.scss';
import NavHeader from './nav-header';

const Header: React.FC = function () {
  const { ref, setOpen, open } = useComponentVisible();
  const { isAuthenticated, role } = useAuth();

  const openMenu = () => {
    setOpen(!open);
  };

  const currentPathname = window.location.pathname;
  const switchView = () => {
    if (isAuthenticated && role === 'alumni') {
      return 'isLoggedIn';
    }
    if (isAuthenticated && role === 'admin') {
      return 'isAdmin';
    }
    return 'default';
  };

  return (
    <header className={`header ${switchView()}`}>
      <div className="innerHeaderContainer">
        <NavLink
          data-testid="logoHome"
          to={isAuthenticated ? currentPathname : RouteConstants.LANDING_PAGE}
          aria-label="home"
        >
          <img className="logo" src={logo} alt="Slalom Build Logo" />
        </NavLink>

        <div ref={ref}>
          <MenuIcon className="hamburgerMenu" onClick={openMenu} />
          <nav
            className={open ? 'navigationMenu open' : 'navigationMenu'}
            data-testid="header-navigation"
          >
            <ul className="navigationList">
              <NavHeader view={switchView()} />
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
