const getEventsQuery: string = `
{
  eventCollection(
    skip: 0
    limit: 100
    order: [startDate_ASC]
  ) {
    total
    skip
    limit
    items {
      sys {
        id
      }
      title
      description {
        json
      }
      startDate
      endDate
      location
      bannerImage {
        url
      }
      eligibleParticipant
    }
  }
}`;

export default getEventsQuery;
