import { TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import './custom-pronouns.scss';

interface CustomPronounProps {
  disabled: boolean;
  value: string;
  onClick: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const CustomPronoun: FC<CustomPronounProps> = function ({
  disabled,
  onChange,
  value,
  onClick,
}: CustomPronounProps) {
  const maxInputCharLength: number = 20;
  return (
    <div className="custom-container">
      <TextField
        onClick={onClick}
        className="text-field"
        value={value}
        disabled={disabled}
        inputProps={{ maxLength: maxInputCharLength }}
        label="Custom Pronouns*"
        fullWidth
        onChange={onChange}
      />
    </div>
  );
};

export default CustomPronoun;
