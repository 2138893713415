import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { LOGIN, ADMIN_LOGIN } from '../../routeConstants';

const ProtectedRoute = function () {
  const { isAuthenticated, role } = useAuth();

  if (!isAuthenticated) {
    if (role === 'admin') {
      return <Navigate to={ADMIN_LOGIN} />;
    }

    if (role === 'alumni') {
      return <Navigate to={LOGIN} />;
    }
  }

  return <Outlet />;
};

export default ProtectedRoute;
