import { useMemo, useReducer } from 'react';
import { createUser } from '../services/user.service';
import { UserData } from './userConstants';
import UserRegistrationFormContext, {
  defaultState,
  RegistrationControls,
} from './UserRegistrationFormContext';

const userReducer = (
  state: UserData,
  payload: Partial<UserData>
): UserData => ({ ...state, ...payload });

const UserRegistrationFormProvider: React.FC<{ children?: React.ReactNode }> =
  function ({ children }: { children?: React.ReactNode }) {
    const [userData, updateUserData] = useReducer(userReducer, defaultState);

    const submit = async (): Promise<Partial<UserData> | string> => {
      const response = await createUser(userData).then((result) => {
        if (result instanceof Error) {
          return result?.message;
        }
        return result;
      });
      return response;
    };

    const registrationReducer: RegistrationControls = useMemo(
      () => ({ userData, updateUserData, submit }),
      [userData, updateUserData, submit]
    );

    return (
      <UserRegistrationFormContext.Provider value={registrationReducer}>
        {children}
      </UserRegistrationFormContext.Provider>
    );
  };

export default UserRegistrationFormProvider;
