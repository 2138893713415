import { KeyboardArrowUpRounded } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import './multi-select.scss';
import xCross from '../../../assets/xcross.svg';
import { toSentenceCase } from '../../../utils/string.utils';

interface MultiSelectProps {
  userDataArray: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
  labelText: string;
  options: string[];
  onDelete: (key: string) => void;
  className?: string;
  width?: number;
  chipsVisible?: number;
}

const MultiSelect: React.FC<MultiSelectProps> = function ({
  userDataArray,
  onChange,
  labelText,
  options,
  onDelete,
  className = '',
  width = 256,
  chipsVisible = 1,
}: MultiSelectProps) {
  const [open, setOpen] = React.useState(false);
  const [focus, setFocus] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const MenuProps = {
    PaperProps: {
      style: {
        minWidth: width,
        transform: 'translateY(4px)',
      },
    },
  };

  const deleteIcon = <img src={xCross} alt="" />;
  const renderChips = (selected: string[]) =>
    selected.map((current) => (
      <Chip
        className="box"
        key={current}
        label={toSentenceCase(current)}
        deleteIcon={deleteIcon}
        onDelete={() => {
          onDelete(current);
          setOpen(false);
          setFocus(false);
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      />
    ));

  const renderSelected = (selected: string[]) => (
    <>
      {renderChips(selected.slice(0, chipsVisible))}
      {selected.length > chipsVisible && (
        <div className="box" key="overflow">{`+${
          selected.length - chipsVisible
        }`}</div>
      )}
    </>
  );

  return (
    <FormControl className={`multi-select-form-control ${className}`}>
      <InputLabel
        id="multi-select-label"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseDown={() => {
          setOpen(true);
          setFocus(true);
        }}
        className={`${userDataArray.length && 'selected-items'} ${
          !focus && 'blurred'
        } ${hover && 'hover'}`}
      >
        {labelText}
      </InputLabel>
      <Select
        multiple
        labelId="multi-select-label"
        className={`user-array-select ${
          userDataArray.length && 'selected-items'
        } ${!focus && 'blurred'} ${hover && 'hover'}`}
        value={userDataArray}
        renderValue={renderSelected}
        onChange={onChange}
        IconComponent={KeyboardArrowUpRounded}
        MenuProps={MenuProps}
        data-testid="multi-select"
        open={open}
        onClose={() => {
          setFocus(false);
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
          setFocus(true);
        }}
        input={<OutlinedInput label={labelText} />}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            id="menu-item"
            data-testid="menu-item"
          >
            <Checkbox
              checked={userDataArray.includes(option)}
              checkedIcon={<CheckIcon />}
            />
            <ListItemText primary={toSentenceCase(option)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
