import { FunctionComponent } from 'react';
import useAuth from '../../hooks/useAuth';
import { ADMIN } from '../../routeConstants';
import buildLogo from './assets/build_logo.svg';
import FooterLinks, { FooterLinksConfig } from './footer-links';
import FooterSocials from './footer-socials';
import './footer.scss';

type PropsFooter = {
  legalText?: string;
};

export const nonAdminArray: FooterLinksConfig[] = [
  {
    testId: 'slalom',
    href: 'https://www.slalom.com/',
    text: 'Slalom',
  },

  { testId: 'build', href: 'https://www.slalombuild.com/', text: '_build' },

  {
    testId: 'careers',
    href: 'https://www.slalombuild.com/careers',
    text: 'Careers & Referrals',
  },

  {
    testId: 'terms',
    href: 'https://www.slalombuild.com/terms-of-use',
    text: 'Terms & Conditions',
  },

  {
    testId: 'privacy',
    href: 'https://www.slalombuild.com/privacy-policy',
    text: 'Privacy',
  },

  { testId: 'contact-us', href: 'get/rid/of/warning', text: 'Contact us' },

  {
    testId: 'login-admin',
    href: `${process.env.REACT_APP_URL}admin/login`,
    text: 'Admin log in',
  },
];

export const adminArray: FooterLinksConfig[] = [
  {
    testId: 'workday',
    href: 'https://www.myworkday.com/slalom/d/home.htmld',
    text: 'Workday',
  },

  {
    testId: 'careers',
    href: 'https://www.slalombuild.com/careers',
    text: 'Careers & Referrals',
  },
  {
    testId: 'slalom',
    href: 'https://www.slalom.com/',
    text: 'Slalom',
  },

  {
    testId: 'build',
    href: 'https://www.slalombuild.com/',
    text: '_build',
  },
];

const Footer: FunctionComponent<PropsFooter> = function ({ legalText }) {
  const currentPathname = window.location.pathname;
  const { isAuthenticated, logoutUser, role } = useAuth();

  const switchView = () => {
    if (isAuthenticated && role === 'admin') {
      return adminArray;
    }
    return nonAdminArray;
  };

  return (
    <footer data-testid="footer" className="footer">
      <section className="info">
        <img src={buildLogo} alt="Slalom Build Logo" />
        <p>{legalText}</p>
        <FooterLinks linkConfigs={switchView()} />
        {!currentPathname.includes(ADMIN.slice(0, -1)) && <FooterSocials />}
        {(currentPathname.includes(ADMIN.slice(0, -1)) || isAuthenticated) && (
          <div
            onClick={logoutUser}
            onKeyDown={logoutUser}
            role="button"
            tabIndex={0}
          >
            <p className="eyebrow-text log-out">Log out</p>
          </div>
        )}
        <p className="eyebrow-text bold">COPYRIGHT 2022-2022 SLALOM INC.</p>
      </section>
    </footer>
  );
};

Footer.defaultProps = {
  legalText:
    'We help to unlock the power of teams and technology, so you can confidently plan, design & build tomorrow. \
  For your customers, for your people, for your entire organization.',
};

export default Footer;
