import { TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import './text-input-field.scss';

interface TextInputFieldProps {
  value: string;
  label: string;
  ariaLabel?: string;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export const TextInputField: FC<TextInputFieldProps> = function ({
  value,
  label,
  ariaLabel = label,
  disabled = false,
  onChange,
  className = '',
}: TextInputFieldProps) {
  return (
    <TextField
      className={`text-field input ${value && 'has-input'} ${className}`}
      value={value}
      label={label}
      aria-label={ariaLabel}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default TextInputField;
