import './fun-facts.scss';

interface FunFactProps {
  facts: FunFactItem[];
}

export interface FunFactItem {
  value: string;
  title: string;
}

const funFact = function (fact: FunFactItem): JSX.Element {
  return (
    <div className="fun-fact" data-testid="fun-fact" key={fact.title}>
      <h3 className="value">{fact.value}</h3>
      <p className="hero title">{fact.title}</p>
    </div>
  );
};

export const FunFacts: React.FC<FunFactProps> = function ({
  facts,
}: FunFactProps) {
  const factRow: JSX.Element[] = [];

  // Currently only supporting maximum of 4 fun facts
  facts.slice(0, 4).forEach((fact: FunFactItem) => {
    factRow.push(funFact(fact));
  });

  return <section className="fun-fact-container">{factRow}</section>;
};

export default FunFacts;
