import { SelectChangeEvent } from '@mui/material/Select';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  emailRegExp,
  UserData,
} from '../../registration-context/userConstants';
import UserRegistrationFormContext from '../../registration-context/UserRegistrationFormContext';
import { REGISTRATION, REGISTRATION_SLALOM_INFO } from '../../routeConstants';
import { SolidButton } from '../common/solid-button/solid-button';
import ContactInfo from './contact-info/contact-info';
import Name from './name/name';
import {
  PronounDropdownOptions,
  PronounDropdownOptionsPt2,
  PronounSelect,
} from './pronouns/pronouns';
import './registration-basic-info.scss';

const RegistrationBasicInfo: FC = function () {
  // NOTE: Add this line to access user state and dispatch (consumer hook for the context)
  const { userData: state, updateUserData: dispatch } = useContext(
    UserRegistrationFormContext
  );
  const navigate: NavigateFunction = useNavigate();
  const navigateToRegistrationSlalomInfo = () => {
    navigate(`${REGISTRATION}${REGISTRATION_SLALOM_INFO}`);
  };
  const [userData, setUserData] = useState<UserData>(state);
  // helper function to retrieve dropdown selected from userData
  const getDropdownPronouns = (): string[] => {
    if (userData.pronouns?.indexOf('/')) {
      return userData.pronouns.split('/');
    }
    return [];
  };
  const wasDropdownSelected = (): boolean => {
    const pronouns = getDropdownPronouns();
    return (
      PronounDropdownOptions.includes(pronouns[0]) &&
      PronounDropdownOptionsPt2.includes(pronouns[1])
    );
  };
  // gets the radio option previously selecteed
  const getRadioOption = (): string => {
    if (userData.pronouns) {
      return wasDropdownSelected() ? 'dropdown' : 'custom';
    }
    return userData.email ? 'notDisclosed' : 'dropdown';
  };
  const [pronounOption, setPronounOption] = useState<string>(getRadioOption());
  const [dropdownSelect1, setSelected1] = useState<string>(
    wasDropdownSelected() ? getDropdownPronouns()[0] : ''
  );
  const [dropdownSelect2, setSelected2] = useState<string>(
    wasDropdownSelected() ? getDropdownPronouns()[1] : ''
  );
  const [customValue, setCustomValue] = useState<string>(
    !wasDropdownSelected() && userData.pronouns ? userData.pronouns : ''
  );
  const [disabled, setDisabled] = useState<boolean>(
    !userData.pronouns && pronounOption !== 'notDisclosed'
  );
  // checks if both dropdowns are selected or there is input in custom pronouns
  // before either the next button can be pressed or dispatching the data
  const isPronounValid = (
    option: string,
    pronoun1?: string,
    pronoun2?: string,
    custom?: string
  ) => {
    switch (option) {
      case 'dropdown': {
        return (
          (pronoun1 && dropdownSelect2) ||
          (pronoun2 && dropdownSelect1) ||
          (dropdownSelect1 && dropdownSelect2)
        );
      }
      case 'custom': {
        return (custom && custom.length > 0) || customValue.length > 0;
      }
      case 'notDisclosed': {
        return true;
      }
      default: {
        return false;
      }
    }
  };
  // hanndles changes for dropdown selection, sets enabled if 2 selections were made
  const handleDropdownChange = (event: SelectChangeEvent) => {
    // if this selection is included in the first dropdown options
    const pronouns = getDropdownPronouns();
    if (PronounDropdownOptions.includes(event.target.value)) {
      setSelected1(event.target.value);
      if (pronouns) {
        setUserData({
          ...userData,
          pronouns: `${event.target.value}/${pronouns[1]}`,
        });
      } else {
        setUserData({
          ...userData,
          pronouns: `${event.target.value}/${dropdownSelect2}`,
        });
      }
      setDisabled(!isPronounValid('dropdown', event.target.value));
    } else {
      // if this selection is included in the second dropdown options
      setSelected2(event.target.value);
      if (pronouns) {
        setUserData({
          ...userData,
          pronouns: `${pronouns[0]}/${event.target.value}`,
        });
      } else {
        setUserData({
          ...userData,
          pronouns: `${dropdownSelect1}/${event.target.value}`,
        });
      }
      setDisabled(!isPronounValid('dropdown', undefined, event.target.value));
    }
  };

  // handles changes with custom and sets user data to corresponding changes
  const handleCustomChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, pronouns: event.target.value });
    setCustomValue(event.target.value);
    if (event.target.value.length < 1) {
      setDisabled(true);
    } else {
      setDisabled(
        !isPronounValid('custom', undefined, undefined, event.target.value)
      );
    }
  };
  // changes the pronoun option state and makes pronouns undefined if not disclosed is selected
  const handlePronounOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPronounOption(event.target.value);
    setDisabled(!isPronounValid(event.target.value));
    switch (event.target.value) {
      case 'dropdown': {
        if (dropdownSelect1 && dropdownSelect2) {
          setUserData({
            ...userData,
            pronouns: `${dropdownSelect1}/${dropdownSelect2}`,
          });
        }
        break;
      }
      case 'custom': {
        if (customValue) {
          setUserData({ ...userData, pronouns: `${customValue}` });
        }
        break;
      }
      default: {
        setUserData({ ...userData, pronouns: undefined });
      }
    }
  };
  const handleChange =
    (prop: keyof UserData) => (event: ChangeEvent<HTMLInputElement>) => {
      setUserData({ ...userData, [prop]: event.target.value });
    };

  const [valid, setValid] = useState<string>('');
  useEffect(() => {
    if (userData.email === '') {
      setValid('');
    } else {
      setValid(emailRegExp.test(userData.email) ? 'valid' : 'invalid');
    }
  }, [userData.email]);

  // Enable button logic for the entire page
  const enableButton: boolean =
    userData.firstName !== '' &&
    userData.lastName !== '' &&
    !disabled &&
    userData.email !== '' &&
    (valid === '' || valid === 'valid');

  return (
    <div className="registration-info">
      <section className="info-box">
        <span className="step">Step 1 of 4</span>
        <h3 className="title">First, let&apos;s set up your alumni account</h3>
        <ContactInfo
          onChange={handleChange}
          email={userData.email}
          status={valid}
        />
        <h4 className="name">Name</h4>
        <Name
          firstName={userData.firstName}
          lastName={userData.lastName}
          preferredName={userData.preferredName ?? ''}
          onChange={handleChange}
        />
        <PronounSelect
          onPronounOptionChange={handlePronounOptionChange}
          onDropdownChange={handleDropdownChange}
          onCustomChange={handleCustomChange}
          pronoun1Value={dropdownSelect1}
          pronoun2Value={dropdownSelect2}
          customValue={customValue}
          defaultSelected={pronounOption}
        />
        <SolidButton
          className="next-button"
          buttonText="Next"
          onClick={() => {
            navigateToRegistrationSlalomInfo();
            dispatch(userData);
          }}
          rightIconType="right-caret"
          disabled={!enableButton}
          isWhiteBackground
        />
      </section>
    </div>
  );
};

export default RegistrationBasicInfo;
