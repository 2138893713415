import UserRole from 'enums/userRole.enums';
import IdToken from 'interfaces/IdToken.interface';
import jwtDecode from 'jwt-decode';
import { AuthChallenge } from 'types/authChallenges.types';
import { AUTHENTICATION_RESULTS } from '../constants/auth.constants';
import AuthenticationResult from '../interfaces/AuthenticationResult.interface';
import { BaseResponse } from '../interfaces/BaseResponse.interface';
import { LoginResponse } from '../interfaces/LoginResponse.interface';
import client from './http.service';

/*
  async login function that takes in a valid body for logging in and
  returns the Auth results including access token which will be stored
*/
export const loginUser = async (
  email: string,
  password: string,
  userRole: UserRole
): Promise<LoginResponse> => {
  try {
    const response = await client.post<BaseResponse<LoginResponse>>(
      `/login?role=${userRole}`,
      {
        email,
        password,
      }
    );

    return response.data.result;
  } catch (error) {
    throw new Error(
      `An error occured when attempting to sign in to ${email}: ${error}`
    );
  }
};

// reset password function calls the login endpoint with the correct parameters
export const resetPassword = async (
  email: string,
  password: string,
  challengeName: AuthChallenge,
  sessionId: string
): Promise<LoginResponse> => {
  try {
    const response = await client.post<BaseResponse<LoginResponse>>('/login', {
      email,
      password,
      challengeName,
      sessionId,
    });

    return response.data.result;
  } catch (error) {
    throw new Error(
      `An error occured when attempting to sign in to ${email}: ${error}`
    );
  }
};

export const getAttributeFromToken = (attributeName: string) => {
  try {
    const body = sessionStorage.getItem(AUTHENTICATION_RESULTS);
    if (body) {
      const authResults = JSON.parse(body) as AuthenticationResult;
      const decoded = jwtDecode<IdToken>(authResults.IdToken);

      return decoded[attributeName];
    }
  } catch (error) {
    throw new Error('An error occured when attempting to decode token');
  }
  return null;
};

export default { loginUser, resetPassword, getAttributeFromToken };
