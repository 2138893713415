import { FC } from 'react';
import './contact-info-card.scss';
import Icon from '../../common/icons/icon';

export type ContactInfoCardProps = {
  icon: 'mailOutlined' | 'localPhone' | 'locationOnOutlined';
  title: string;
  text: string;
  contactLink: string;
  href: string;
};

/*
 * title: The title of the card component
 * text: The text description of the card component
 * contactLink: The text which redirects the user
 * href: The link to redirect to
 */

export const ContactInfoCard: FC<ContactInfoCardProps> = function ({
  icon,
  title,
  text,
  contactLink,
  href,
}) {
  return (
    <div className="container">
      <div className="content-container">
        <Icon iconName={icon} />
        <h3 className="title">{title}</h3>
        <p className="text hero">{text}</p>
        <a
          className="link"
          href={href}
          target={title === 'Office' ? '_blank' : '_self'}
          rel="noreferrer"
        >
          {contactLink}
        </a>
      </div>
    </div>
  );
};
