import DoneIcon from '@mui/icons-material/Done';
import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import eyeClosed from '../../assets/eye-closed.svg';
import eye from '../../assets/eye.svg';
import { LoginData } from '../../login-context/loginConstants';
import './password.scss';

export enum PasswordStatus {
  INITIAL,
  VALID,
  ERROR,
}
interface PropsPassword {
  onBlur?: () => void;
  onChange: (
    prop: keyof LoginData
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  password: string;
  label: string;
  testId?: string;
  field: keyof LoginData;
  status: PasswordStatus;
}

const properIcon = (
  status: PasswordStatus,
  showPassword: boolean,
  focus: boolean
) => {
  if (status === PasswordStatus.VALID) {
    return <DoneIcon className="done-icon" />;
  }
  return showPassword ? (
    <img
      className={`visibility-icon ${focus && 'focus'} ${
        status === PasswordStatus.ERROR && 'error'
      }`}
      src={eye}
      alt=""
      data-testid="invisible"
    />
  ) : (
    <img
      className={`visibility-icon ${focus && 'focus'} ${
        status === PasswordStatus.ERROR && 'error'
      }`}
      src={eyeClosed}
      alt=""
      data-testid="visible"
    />
  );
};

export const Password: FunctionComponent<PropsPassword> = function ({
  onBlur = () => {},
  onChange,
  password,
  label,
  testId = label,
  field,
  status,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [focus, setFocus] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnBlur = () => {
    onBlur();
    setFocus(false);
  };

  return (
    <div className="password-container">
      <FormControl fullWidth variant="outlined" className="form">
        <InputLabel
          htmlFor="outlined-adornment-password"
          className={`${password ? 'blurred' : 'label'} ${
            status === PasswordStatus.ERROR && 'error'
          }`}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          onBlur={handleOnBlur}
          data-testid={testId}
          type={showPassword ? 'text' : 'password'}
          value={password}
          className={`${password ? 'has-input' : 'no-input'} ${
            status === PasswordStatus.ERROR && 'error'
          }`}
          onChange={onChange(field)}
          onFocus={() => setFocus(true)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                className="icon-button"
              >
                {properIcon(status, showPassword, focus)}
              </IconButton>
            </InputAdornment>
          }
        />
        {status === PasswordStatus.ERROR && (
          <FormHelperText error className="error-msg">
            Invalid password
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default Password;
