import { styled } from '@mui/material';
import '../../../css/color.scss';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

const SlalomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#09091C',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#09091C',
    fontSize: 16,
    padding: 16,
    fontFamily: 'Slalom Sans',
  },
}));

export default SlalomTooltip;
