import { ContactInfoCardProps } from '../contact-info-card/contact-info-card';

const email = 'info@pruthvi.design';
const phone = '+0000000000';
const address = '85 Richmond Street #500 Toronto, ON MSH 2C9';

export const contactInfoSectionArray: ContactInfoCardProps[] = [
  {
    icon: 'mailOutlined',
    title: 'Email',
    text: 'Got questions? Send us a note!',
    contactLink: email,
    // opens default email system to the email contack_link of the email set
    href: `mailto:${email}`,
  },
  {
    icon: 'localPhone',
    title: 'Phone',
    text: 'You can also call us below',
    contactLink: phone,
    // opens window prompt to call number set
    href: `tel:${phone}`,
  },
  {
    icon: 'locationOnOutlined',
    title: 'Office',
    text: 'Come on down and visit us in person',
    contactLink: address,
    // opens new window to google maps with specified location
    href: `https://maps.google.com?q=${address}`,
  },
];

export default contactInfoSectionArray;
