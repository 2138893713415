import { EventCardDetails } from 'components/event-cards/event-card-details';
import getEventsQuery from 'components/event-cards/get-events-query';
import { useState, useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getEvents } from 'services/contentful.service';
import { BLOGS_AND_ARTICLES } from '../../routeConstants';
import { articlePreviewArray } from '../article-preview/article-preview-array';
import ArticlePreviewList from '../article-preview/article-preview-list';
import OutlinedButton from '../common/outlined-button/outlined-button';
import ContactInfoSection from '../contact-info/contact-info-section/contact-info-section';
import contactInfoSectionArray from '../contact-info/contact-info-section/contact-info-section-array';
import EventCardList from '../event-cards/event-card-list';
import FeaturedStories from '../featured-stories/featured-stories';
import { featuredStoriesArray } from '../featured-stories/featured-stories-array';
import HeroExperience from '../hero-experience/HeroExperience';
import MyRelayVideo from '../my-relay-videos/my-relay-video';
import { mainVideo } from '../my-relay-videos/videos-array';
import './my-relay.scss';

const MyRelay = function () {
  const navigate: NavigateFunction = useNavigate();

  const [eventArray, setEventArray] = useState<EventCardDetails[]>([]);

  useEffect(() => {
    (async () => {
      const result = await getEvents(getEventsQuery);
      setEventArray(result);
    })();
  }, []);

  return (
    <div className="my-relay-page">
      <HeroExperience />
      <EventCardList eventArray={eventArray} />
      <FeaturedStories stories={featuredStoriesArray} />
      <div>
        <ArticlePreviewList articlePreviewArray={articlePreviewArray} />
        <OutlinedButton
          buttonText="See all blogs & articles"
          theme="outlined_dark"
          className="all-blogs-button"
          onClick={() => navigate(BLOGS_AND_ARTICLES)}
        />
      </div>
      <MyRelayVideo mainVideoArray={mainVideo} />
      <ContactInfoSection contactInfo={contactInfoSectionArray} />
    </div>
  );
};

export default MyRelay;
