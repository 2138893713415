import './event-card.scss';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ALUMNI, ALUMNI_MY_EVENTS } from '../../routeConstants';
import { EventCardDetails } from './event-card-details';

interface EventCardProps {
  event: EventCardDetails;
}

const EventCard: React.FC<EventCardProps> = function ({
  event,
}: EventCardProps) {
  const {
    title,
    subtitle,
    eligibleParticipants,
    date,
    time,
    location,
    imageUrl,
    altText = '',
    href = `${ALUMNI}${ALUMNI_MY_EVENTS}`,
  }: EventCardDetails = event;
  const navigate: NavigateFunction = useNavigate();
  return (
    <div
      className="event-card"
      data-testid="event-card"
      onClick={() => navigate(href)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') navigate(href);
      }}
      role="link"
      tabIndex={0}
    >
      <div className="event-picture-container">
        <div className="location-box">{location}</div>
        <img className="event-picture" src={imageUrl} alt={altText} />
      </div>
      <section className="event-details">
        <div className="event-time-details">
          <h4>{date}</h4>
          <p>{time}</p>
        </div>
        <div className="event-text-details">
          <p className="bold">{title}</p>
          <p>{subtitle}</p>
          <p className="caption">{eligibleParticipants}</p>
        </div>
      </section>
    </div>
  );
};

export default EventCard;
