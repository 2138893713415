import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UserStatus from 'enums/user-status.enum';
import { useState } from 'react';
import useCollapse from 'react-collapsed';
import {
  AccountStatus,
  UserData,
} from '../../registration-context/userConstants';
import { updateUserStatus } from '../../services/user.service';
import { OutlinedButton } from '../common/outlined-button/outlined-button';
import './user-card.scss';

// TODO: ALUM-248 call API to update status to rejected
// eslint-disable-next-line
function rejectOnClick(
  email: string,
  setStatus: (accountStatus: AccountStatus) => void
) {
  try {
    updateUserStatus(email, UserStatus.Rejected);
    setStatus(UserStatus.Rejected);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }
}

// TODO: ALUM-248 call API to update status to active (approved)
// eslint-disable-next-line
function approveOnClick(
  email: string,
  setStatus: (accountStatus: AccountStatus) => void
) {
  try {
    updateUserStatus(email, UserStatus.Active);
    setStatus(UserStatus.Active);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }
}

// TODO: ALUM-102 call API to update status to revoked
// eslint-disable-next-line
function revokeOnClick(
  email: string,
  setStatus: (accountStatus: AccountStatus) => void
) {
  try {
    updateUserStatus(email, UserStatus.Revoked);
    setStatus(UserStatus.Revoked);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }
}

const acceptedUser = function (
  email: string,
  setStatus: (accountStatus: AccountStatus) => void
): JSX.Element {
  return (
    <div className="button">
      <OutlinedButton
        buttonText="Revoke"
        theme="outlined_dark"
        onClick={() => revokeOnClick(email, setStatus)}
        className="revoke"
      />
    </div>
  );
};

const pendingUser = function (
  email: string,
  setStatus: (accountStatus: AccountStatus) => void
): JSX.Element {
  return (
    <div className="buttons">
      <OutlinedButton
        buttonText="Reject"
        theme="outlined_dark"
        onClick={() => rejectOnClick(email, setStatus)}
        className="reject"
      />
      <OutlinedButton
        buttonText="Approve"
        theme="outlined_dark"
        onClick={() => approveOnClick(email, setStatus)}
        className="approve"
      />
    </div>
  );
};

const UserCard: React.FC<Partial<UserData>> = function ({
  email,
  preferredName,
  firstName,
  lastName,
  slalomGlobalTeam,
  homeMarket,
  slalomCapability,
  prevPeopleManagerName,
  accountStatus,
  rejectReason,
}: Partial<UserData>) {
  const [rotateButton, setRotateButton] = useState(false);
  const [status, setStatus] = useState(accountStatus);

  const { getCollapseProps, getToggleProps } = useCollapse();
  const buttons = function (): any {
    if (email) {
      if (status === UserStatus.AwaitingApproval) {
        return pendingUser(email, setStatus);
      }
      if (status === UserStatus.Active) {
        return acceptedUser(email, setStatus);
      }
    }
    return null;
  };
  const moreUserData = function (): JSX.Element {
    return (
      <div className="more-user-info">
        <div>
          <p data-testid="capability" className="capability">
            <b>Capability:</b> {slalomCapability}
          </p>
          <p data-testid="peopleLeader" className="people-leader">
            <b>People leader:</b> {prevPeopleManagerName}
          </p>
          {status === UserStatus.Rejected ? (
            <p data-testid="rejectReason" className="reject-reason">
              <b>Reject Reason:</b>{' '}
              {rejectReason === undefined ? '-' : rejectReason}
            </p>
          ) : null}
        </div>
        {buttons()}
      </div>
    );
  };

  return (
    <div data-testid="userCard" className="user-card">
      <div className="basic-user-data">
        <p data-testid="name" className="name">
          <b>{`${preferredName ?? firstName} ${lastName}`}</b>{' '}
          <i>- Slalom {slalomGlobalTeam}</i> - {homeMarket}
        </p>
        {/* rejected, active (approved) and inactive users should have collapsable card */}
        {status === UserStatus.Active ||
        status === UserStatus.Rejected ||
        status === UserStatus.Inactive ||
        status === UserStatus.Revoked ? (
          <KeyboardArrowDownIcon
            className={rotateButton ? 'dropdown hover' : 'hover'}
            aria-label="dropdown-arrow"
            {...getToggleProps({
              onClick: () => setRotateButton(!rotateButton),
            })}
          />
        ) : null}
      </div>
      {status === 'active' ||
      status === 'rejected' ||
      status === 'inactive' ||
      status === 'revoked' ? (
        <div {...getCollapseProps()}>{moreUserData()}</div>
      ) : (
        <div>{moreUserData()}</div>
      )}
    </div>
  );
};

export default UserCard;
