import React from 'react';
import Icon from '../common/icons/icon';
import './feature-card.scss';

type SocialIcon =
  | 'facebook'
  | 'instagram'
  | 'twitter'
  | 'linkedIn'
  | 'locationOnOutlined'
  | 'localPhone'
  | 'mailOutlined'
  | 'briefcase'
  | 'menu';

export type FeatureCardProps = {
  icon: SocialIcon;
  title: string;
  text: string;
};

const FeatureCard: React.FC<FeatureCardProps> = function ({
  icon,
  title,
  text,
}) {
  return (
    <div className="FeatureCardContainer">
      <div className="icon">
        <Icon iconName={icon} />
        <h3 className="title">{title}</h3>
        <p className="text">{text}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
