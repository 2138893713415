import { KeyboardArrowUpRounded } from '@mui/icons-material';
import { OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FC, useState } from 'react';
import './dropdown-menu.scss';

interface DropDownMenuProps {
  placeholder: string;
  disabled: boolean;
  items: string[];
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  className?: string;
}

export const DropDownMenu: FC<DropDownMenuProps> = function ({
  placeholder,
  items,
  disabled,
  onChange,
  value,
  className = '',
}: DropDownMenuProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  return (
    <FormControl disabled={disabled} className={className}>
      <InputLabel
        className={`label ${!focus && 'blurred'} ${value && 'selected'} ${
          hover && 'hover'
        }`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {placeholder}
      </InputLabel>
      <div className="menu-container">
        <Select
          data-testid="dropdown-select"
          label={placeholder}
          onChange={onChange}
          value={value}
          input={<OutlinedInput label={placeholder} />}
          className={`pronoun-select ${!focus && 'blurred'} ${
            value && 'selected'
          } ${hover && 'hover'}`}
          open={open}
          onClose={() => {
            setFocus(false);
            setOpen(false);
          }}
          onOpen={() => {
            setOpen(true);
            setFocus(true);
          }}
          IconComponent={KeyboardArrowUpRounded}
        >
          {items.map((name: string) => (
            <MenuItem
              key={name}
              value={name}
              className="item"
              data-testid="dropdown-menu-item"
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FormControl>
  );
};

export default DropDownMenu;
