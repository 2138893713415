import React from 'react';
import './content-teaser.scss';

/**
 * Content Teaser has an image, title, preview text, and a date
 * When the teaser is clicked a stub is launched (link to stub)
 */
export type ContentTeaserProps = {
  img: string;
  title: string;
  text: string;
  link: string;
  date: string;
};

export const ContentTeaser: React.FC<ContentTeaserProps> = function ({
  img,
  date,
  title,
  text,
  link,
}) {
  return (
    <div
      role="button"
      tabIndex={0}
      className="content-teaser"
      onClick={() => (link ? window.open(link) : null)}
      data-testid="content-teaser"
      aria-hidden="true"
    >
      <img src={img} alt={title} />
      <p className="eyebrow-text">{date}</p>
      <h4>{title}</h4>
      <p>{text}</p>
    </div>
  );
};

/**
 * Container contains content teasers
 * Note: there should only be 3 teasers in the container
 * */
type ContainerProps = {
  teaserProps: ContentTeaserProps[];
};

export const ContentTeaserContainer: React.FC<ContainerProps> = function ({
  teaserProps,
}) {
  return (
    <div className="teaser-container" data-testid="teaser-container">
      {/* Print all the Content Teasers with the teaserProps provided formated as a ContentTeaser component */}
      {teaserProps.map((teaser, index) => (
        <ContentTeaser
          key={index}
          img={teaser.img}
          link={teaser.link}
          title={teaser.title}
          date={teaser.date}
          text={teaser.text}
        />
      ))}
    </div>
  );
};
