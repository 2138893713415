import { createContext, Dispatch } from 'react';
import { LoginData } from './loginConstants';

export const defaultState: LoginData = {
  email: '',
  password: '',
  resetPassword: '',
  confirmPassword: '',
};

export interface LoginControls {
  loginData: LoginData;
  updateLoginData: Dispatch<Partial<LoginData>>;
  submit: (loginData: LoginData) => void;
}

const UserLoginFormContext = createContext<LoginControls>({
  loginData: defaultState,
  updateLoginData: () => null,
  submit: () => null,
});

export default UserLoginFormContext;
