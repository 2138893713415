import './hero-landing.scss';
import { FunctionComponent } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import * as RouteConstants from '../../routeConstants';
import { OutlinedButton } from '../common/outlined-button/outlined-button';
import { SolidButton } from '../common/solid-button/solid-button';

type HeroProps = {
  description?: string;
};

const Hero: FunctionComponent<HeroProps> = function ({ description }) {
  const navigate: NavigateFunction = useNavigate();
  const navigateToRegistrationLanding = () => {
    navigate(RouteConstants.REGISTRATION);
  };

  return (
    <section className="hero-section">
      <div className="hero-text">
        <header>
          <h2 className="welcome">
            Welcome to Relay
            <sup className="exponent">BETA</sup>
          </h2>
        </header>
        <p className="description hero" data-testid="hero-description">
          {description}
        </p>
        <div className="hero-cta">
          <div className="invite bold ">
            <SolidButton
              buttonText="Register"
              onClick={navigateToRegistrationLanding}
            />
          </div>
          {/* Using empty onClick for learn more button before navigation is determined */}
          <div className="learn_more">
            <OutlinedButton
              buttonText="Learn More"
              onClick={() => null}
              theme="outlined_light"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

// Default description for the component
Hero.defaultProps = {
  description:
    'We help to unlock the power of teams and technology, so you can confidently plan, design & build tomorrow.  \
  For your customers, for your people, for your entire organization.',
};

export default Hero;
