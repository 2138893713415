import { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import UserRegistrationFormContext from '../../registration-context/UserRegistrationFormContext';
import {
  REGISTRATION,
  REGISTRATION_NOTIFICATION_INFO,
} from '../../routeConstants';
import { OutlinedButton } from '../common/outlined-button/outlined-button';
import { SolidButton } from '../common/solid-button/solid-button';
import ProfileSummary from '../profile-summary/ProfileSummary';
import EmploymentInfo from './employment-info';
import './registration-profile-info.scss';

const RegistrationProfileInfo: React.FC = function () {
  const { userData: state, updateUserData: dispatch } = useContext(
    UserRegistrationFormContext
  );

  const [currEmployer, setCurrEmployer] = useState(state.currEmployer);
  const [currPosition, setCurrPosition] = useState(state.currPosition);
  const [linkedInUrl, setLinkedInUrl] = useState(state.linkedInUrl);
  const [websiteUrl, setWebsiteUrl] = useState(state.websiteUrl);
  const [biography, setBiography] = useState(state.biography);

  const [currEmployerVisible, setCurrEmployerVisible] = useState(
    state.currEmployerVisible
  );
  const [currPositionVisible, setCurrPositionVisible] = useState(
    state.currPositionVisible
  );
  const [linkedInUrlVisible, setLinkedInUrlVisible] = useState(
    state.linkedInUrlVisible
  );
  const [websiteUrlVisible, setWebsiteUrlVisible] = useState(
    state.websiteUrlVisible
  );
  const [biographyVisible, setBiographyVisible] = useState(
    state.biographyVisible
  );

  const formMaxCharacters: number = 250;
  const navigate: NavigateFunction = useNavigate();
  const navigateToRegistrationNotificationInfo = () => {
    navigate(`${REGISTRATION}${REGISTRATION_NOTIFICATION_INFO}`);
  };
  const navigateBackOne = () => {
    navigate(-1);
  };

  const handleClickNavigation = () => {
    dispatch({
      ...state,
      currEmployer,
      currPosition,
      linkedInUrl,
      websiteUrl,
      biography,
      currEmployerVisible,
      currPositionVisible,
      linkedInUrlVisible,
      websiteUrlVisible,
      biographyVisible,
    });
  };

  return (
    <div className="registration-info">
      <section className="info-box">
        <p className="registration-step">Step 3 of 4</p>
        <h3 className="title">Let’s fill out your profile a bit more.</h3>
        <ProfileSummary />
        <br />
        <EmploymentInfo
          inputTitle="Your Current Employer"
          currentformVal={currEmployer}
          setFormVal={setCurrEmployer}
          visibility={currEmployerVisible}
          setVisibility={setCurrEmployerVisible}
          maxCharacters={50}
        />

        <EmploymentInfo
          inputTitle="Your Current Position"
          currentformVal={currPosition}
          setFormVal={setCurrPosition}
          visibility={currPositionVisible}
          setVisibility={setCurrPositionVisible}
          maxCharacters={50}
        />

        <EmploymentInfo
          inputTitle="LinkedIn Profile URL"
          currentformVal={linkedInUrl}
          setFormVal={setLinkedInUrl}
          visibility={linkedInUrlVisible}
          setVisibility={setLinkedInUrlVisible}
          maxCharacters={formMaxCharacters}
        />

        <EmploymentInfo
          inputTitle="Website"
          currentformVal={websiteUrl}
          setFormVal={setWebsiteUrl}
          visibility={websiteUrlVisible}
          setVisibility={setWebsiteUrlVisible}
          maxCharacters={formMaxCharacters}
        />

        <EmploymentInfo
          inputTitle="Bio"
          currentformVal={biography}
          setFormVal={setBiography}
          visibility={biographyVisible}
          setVisibility={setBiographyVisible}
          maxCharacters={formMaxCharacters}
        />
        <div className="button-container profile-box">
          <div className="left-buttons">
            <OutlinedButton
              className="back-button"
              theme="outlined_dark"
              onClick={() => {
                handleClickNavigation();
                navigateBackOne();
              }}
              rightIconType="left-caret"
              isIconOnly
            />
            <SolidButton
              className="next-button"
              buttonText="Next"
              onClick={() => {
                handleClickNavigation();
                navigateToRegistrationNotificationInfo();
              }}
              rightIconType="right-caret"
              isWhiteBackground
            />
          </div>
          <button
            type="button"
            className="skip-button"
            onClick={navigateToRegistrationNotificationInfo}
          >
            Skip for now and complete later
          </button>
        </div>
      </section>
    </div>
  );
};

export default RegistrationProfileInfo;
