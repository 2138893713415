import {
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import './employment-info.scss';

interface EmployementInfoProps {
  inputTitle: string;
  currentformVal?: string;
  setFormVal: (text: string) => void;
  visibility: boolean;
  setVisibility: (text: boolean) => void;
  maxCharacters: number;
}

const EmploymentInfo: React.FC<EmployementInfoProps> = function ({
  inputTitle,
  currentformVal,
  setFormVal,
  visibility,
  setVisibility,
  maxCharacters,
}: EmployementInfoProps) {
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormVal(event.target.value);
  };

  const handleVisibilityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setVisibility(event.target.checked);
  };

  const labelVisible: string = 'Visible';
  const labelInvisible: string = 'Hidden';

  const controlSwitch = (
    <Switch className="switch" onChange={handleVisibilityChange} />
  );

  return (
    <FormControl className="employment-info-form" id={inputTitle}>
      <TextField
        label={inputTitle}
        value={currentformVal}
        onChange={handleFormChange}
        inputProps={{ maxLength: maxCharacters }}
        multiline={inputTitle === 'Bio'}
      />
      <FormControlLabel
        checked={visibility}
        className="switch-label"
        control={controlSwitch}
        label={visibility ? labelVisible : labelInvisible}
        data-testid="toggle-btn"
      />
    </FormControl>
  );
};

export default EmploymentInfo;
