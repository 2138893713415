import { articleArray } from '../article-preview/article-preview-array';
import ArticlePreviewList from '../article-preview/article-preview-list';
import './blogs-and-articles.scss';

const BlogsAndArticles: React.FC = function () {
  return (
    <div className="blogs-and-articles-page">
      <ArticlePreviewList articlePreviewArray={articleArray} />
    </div>
  );
};

export default BlogsAndArticles;
