import { createContext, Dispatch } from 'react';
import { UserData } from './userConstants';

export const defaultState: UserData = {
  email: '',
  sort_key: 'profile',
  image: '',
  firstName: '',
  lastName: '',
  slalomGlobalTeam: '',
  homeMarket: '',
  marketInterests: [],
  slalomCapability: '',
  prevPeopleManagerName: '',
  currEmployerVisible: true,
  currPositionVisible: true,
  linkedInUrlVisible: true,
  biographyVisible: true,
  interestsVisible: false,
  isEnrolledNotifications: false,
  isEmailExposedAlums: false,
  isTermsConditionsAccepted: false,
  websiteUrlVisible: true,
};

export interface RegistrationControls {
  userData: UserData;
  updateUserData: Dispatch<Partial<UserData>>;
  submit: () => any;
}

const UserRegistrationFormContext = createContext<RegistrationControls>({
  userData: defaultState,
  updateUserData: () => null,
  submit: () => null,
});

export default UserRegistrationFormContext;
