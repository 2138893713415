import { FeaturedStoryDetails } from './featured-stories-array';
import './featured-story.scss';

interface FeaturedStoryProps {
  story: FeaturedStoryDetails;
}
const FeaturedStory: React.FC<FeaturedStoryProps> = function ({
  story,
}: FeaturedStoryProps) {
  const { title, href, imageUrl, alt = '' }: FeaturedStoryDetails = story;
  const mainTitle = (str: string): string =>
    str.substring(0, str.lastIndexOf(' '));
  const lastWordTitle = (str: string): string =>
    str.substring(str.lastIndexOf(' ') + 1);
  return (
    <div className="featured-story" data-testid="featured-story">
      <section className="featured-story-text-container">
        <div className="featured-story-text">
          <h4 className="featured">
            Featured<span className="stories">_Stories</span>
          </h4>
        </div>
        <div
          className="featured-story-title"
          onClick={() => window.open(href, '_blank')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.open(href, '_blank');
            }
          }}
          role="link"
          tabIndex={0}
        >
          <h2 className="title-main">{mainTitle(title)}</h2>
          <h2 className="title-last-word">{lastWordTitle(title)}</h2>
        </div>
      </section>
      <div className="featured-story-image-container">
        <img className="featured-story-image" src={imageUrl} alt={alt} />
      </div>
    </div>
  );
};

export default FeaturedStory;
