import { FC } from 'react';
import './solid-button.scss';
import rightArrow from '../../../assets/right-arrow.svg';
import rightCaret from '../../../assets/right-caret.svg';

export type SolidButtonTheme = 'text-only' | 'primary';

export type SolidButtonIcons = 'right-caret' | 'right-arrow';

interface SolidButtonProps {
  buttonText?: string;
  onClick: () => void;
  buttonTheme?: SolidButtonTheme;
  className?: string;
  testId?: string;
  rightIconType?: SolidButtonIcons;
  isIconOnly?: boolean;
  disabled?: boolean;
  isWhiteBackground?: boolean;
}

function returnSrc(iconType: SolidButtonIcons): string {
  switch (iconType) {
    case 'right-caret':
      return rightCaret;
    case 'right-arrow':
      return rightArrow;
    default:
      return '';
  }
}

export const SolidButton: FC<SolidButtonProps> = function ({
  buttonText = '',
  onClick,
  buttonTheme = 'primary',
  className = '',
  testId = buttonText,
  rightIconType = undefined,
  isIconOnly = false,
  disabled = false,
  isWhiteBackground = false,
}: SolidButtonProps) {
  return (
    <button
      onKeyDown={onClick}
      type="button"
      className={`solid-button ${buttonTheme} ${className} ${
        isIconOnly && 'icon-only'
      } ${isWhiteBackground && 'white-background'}`}
      data-testid={testId}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText}
      {rightIconType && (
        <div className={isIconOnly ? 'icon-box' : ''}>
          <img className="right-icon" src={returnSrc(rightIconType)} alt="" />
        </div>
      )}
    </button>
  );
};

export default SolidButton;
