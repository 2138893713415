import UserRole from 'enums/userRole.enums';
import AuthResults from 'interfaces/AuthenticationResult.interface';
import { useReducer, useState } from 'react';
import * as AUTH_CONSTANTS from '../constants/auth.constants';
import authService from '../services/auth.service';

const authReducer = (): boolean => {
  const exp = sessionStorage.getItem(AUTH_CONSTANTS.EXPIRES_AT);
  const isLoggedIn = exp ? Date.now() <= parseFloat(exp) : false;

  if (!isLoggedIn) {
    sessionStorage.removeItem(AUTH_CONSTANTS.AUTHENTICATION_RESULTS);
    sessionStorage.removeItem(AUTH_CONSTANTS.EXPIRES_AT);
  }

  return isLoggedIn;
};

const useAuthProvider = () => {
  /* 
    parses the results of the login user api call from service and stores
    authentication data in session storage
  */
  const [role, setRole] = useState<UserRole>(UserRole.Alumni);

  const storeSession = (authenticationResults: AuthResults) => {
    const { ExpiresIn } = authenticationResults;

    sessionStorage.setItem(
      AUTH_CONSTANTS.AUTHENTICATION_RESULTS,
      JSON.stringify(authenticationResults)
    );

    const expiresAt = Date.now() + ExpiresIn * 1000;
    sessionStorage.setItem(AUTH_CONSTANTS.EXPIRES_AT, expiresAt.toString());
  };
  const loginUser = async (
    email: string,
    password: string,
    userRole: UserRole = UserRole.Alumni
  ): Promise<boolean> => {
    const {
      AuthenticationResult,
      ChallengeName,
      Session,
      ChallengeParameters,
    } = await authService.loginUser(email, password, userRole);

    if (ChallengeName) {
      sessionStorage.setItem(AUTH_CONSTANTS.CHALLENGE_NAME, ChallengeName);
      sessionStorage.setItem(AUTH_CONSTANTS.SESSION, Session);
    }

    if (ChallengeParameters) {
      sessionStorage.setItem(
        AUTH_CONSTANTS.CHALLENGE_PARAMETERS,
        JSON.stringify(ChallengeParameters)
      );
    }

    if (AuthenticationResult) {
      storeSession(AuthenticationResult);
      setRole(userRole);
      return true;
    }
    return false;
  };

  /*
    calls the resetPassword function in auth services if provided the correct challenge name
    and returns login user or false if challenge name is not preset
  */
  const resetPassword = async (
    email: string,
    password: string,
    challengeName: string,
    sessionId: string
  ): Promise<boolean> => {
    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      const { AuthenticationResult } = await authService.resetPassword(
        email,
        password,
        challengeName,
        sessionId
      );
      if (AuthenticationResult) {
        sessionStorage.removeItem(AUTH_CONSTANTS.CHALLENGE_NAME);
        sessionStorage.removeItem(AUTH_CONSTANTS.SESSION);

        storeSession(AuthenticationResult);

        return true;
      }
    }
    return false;
  };

  const [isAuthenticated, updateIsAuthenticated] = useReducer(
    authReducer,
    false
  );

  const logoutUser = () => {
    if (isAuthenticated) {
      updateIsAuthenticated();
      sessionStorage.removeItem(AUTH_CONSTANTS.AUTHENTICATION_RESULTS);
      sessionStorage.removeItem(AUTH_CONSTANTS.EXPIRES_AT);
      sessionStorage.removeItem(AUTH_CONSTANTS.CHALLENGE_NAME);
      sessionStorage.removeItem(AUTH_CONSTANTS.SESSION);
    }
  };

  const isResetRequired = (): boolean =>
    sessionStorage.getItem(AUTH_CONSTANTS.CHALLENGE_NAME) !== null;

  return {
    loginUser,
    logoutUser,
    isAuthenticated,
    updateIsAuthenticated,
    isResetRequired,
    resetPassword,
    role,
  };
};

export default useAuthProvider;
