import { NavigateFunction, useNavigate } from 'react-router-dom';
import { LANDING_PAGE } from '../../routeConstants';
import { SolidButton } from '../common/solid-button/solid-button';
import './registration-successful.scss';

const RegistrationSuccessful: React.FC = function () {
  const navigate: NavigateFunction = useNavigate();
  const navigateToHome = () => {
    navigate(LANDING_PAGE);
  };
  return (
    <div className="registration-info">
      <section className="info-box">
        <SolidButton
          className="login-button"
          buttonText="Login"
          onClick={navigateToHome}
          rightIconType="right-arrow"
          isWhiteBackground
        />
      </section>
    </div>
  );
};

export default RegistrationSuccessful;
