import { useMemo, useReducer } from 'react';
import { LoginData } from './loginConstants';
import UserLoginFormContext, {
  defaultState,
  LoginControls,
} from './UserLoginFormContext';

const userReducer = (
  state: LoginData,
  payload: Partial<LoginData>
): LoginData => ({ ...state, ...payload });

const UserLoginFormProvider: React.FC<{ children?: React.ReactNode }> =
  function ({ children }: { children?: React.ReactNode }) {
    const [loginData, updateLoginData] = useReducer(userReducer, defaultState);
    // eslint-disable-next-line
    const submit = (loginData: LoginData): void => {};

    const loginReducer: LoginControls = useMemo(
      () => ({ loginData, updateLoginData, submit }),
      [loginData, updateLoginData, submit]
    );

    return (
      <UserLoginFormContext.Provider value={loginReducer}>
        {children}
      </UserLoginFormContext.Provider>
    );
  };

export default UserLoginFormProvider;
