export interface LoginData {
  email: string;
  password: string;
  resetPassword: string;
  confirmPassword: string;
}

export const passwordRegExp: RegExp =
  /^.*(?=.{8,256})((?=.*[!@#$%^&*()\-_=+{};:,<.>?/|\\]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export const emailRegExp: RegExp = /^\S+@\S+\.\S+$/;
