import { SelectChangeEvent } from '@mui/material';
import { useEffect, FC, useState } from 'react';
import {
  UserData,
  AccountStatus,
  SlalomCapability,
  SlalomGlobalTeam,
  SlalomMarket,
} from '../../registration-context/userConstants';
import { getUsers } from '../../services/user.service';
import MultiSelect from '../common/multi-select/multi-select';
import './admin-dashboard.scss';
import UserCard from '../user-card/UserCard';
import EmailSearch from './email-search/email-search';

interface ProfileFilters {
  globalTeams: SlalomGlobalTeam[];
  markets: SlalomMarket[];
  capabilities: SlalomCapability[];
  accountStatuses: AccountStatus[];
}

const AdminDashboard: FC<ProfileFilters> = function ({
  globalTeams,
  markets,
  capabilities,
  accountStatuses,
}: ProfileFilters) {
  const defaultFilters: ProfileFilters = {
    globalTeams: [],
    markets: [],
    capabilities: [],
    accountStatuses: [],
  };
  const [filters, setFilters] = useState<ProfileFilters>(defaultFilters);
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<Partial<UserData>[]>();
  const [page] = useState<number>(1);

  async function loadUsers() {
    const result = await getUsers(page);

    if (result instanceof Error) {
      // eslint-disable-next-line
      console.error(result?.message);
    } else {
      setUsers(result?.data);
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      await loadUsers();
    })();
  }, []);

  type FilterCategory =
    | 'markets'
    | 'capabilities'
    | 'globalTeams'
    | 'accountStatuses';
  // This function triggers the fetch call for get user by email: to be integrated
  const handleSearchClick = () => {};
  // handles the change of the multiselect with the corresponding filter
  const handleFilterChange = (
    event: SelectChangeEvent<string[]>,
    category: FilterCategory
  ) => {
    const {
      target: { value },
    } = event;
    const val = typeof value === 'string' ? [value] : value;
    filters[category as keyof typeof filters] = val;
    setFilters({ ...filters });
  };
  // Delete functions for multiselect, needs to be individual
  // based multiselect's onDelete
  const chipDeleteGlobalTeams = (key: string) => {
    let copy = filters.globalTeams;
    copy = copy?.filter((capability) => capability !== key);
    setFilters({ ...filters, globalTeams: copy });
  };
  const chipDeleteCapabilities = (key: string) => {
    let copy = filters.capabilities;
    copy = copy?.filter((capability) => capability !== key);
    setFilters({ ...filters, capabilities: copy });
  };
  const chipDeleteAccountStatuses = (key: string) => {
    let copy = filters.accountStatuses;
    copy = copy?.filter((capability) => capability !== key);
    setFilters({ ...filters, accountStatuses: copy });
  };
  const chipDeleteMarkets = (key: string) => {
    let copy = filters.markets;
    copy = copy?.filter((capability) => capability !== key);
    setFilters({ ...filters, markets: copy });
  };
  const menuWidth: number = 360;

  const renderUserCard = () => {
    if (!loading) {
      if (users?.length) {
        return users?.map(
          ({
            email,
            firstName,
            preferredName,
            lastName,
            slalomGlobalTeam,
            slalomCapability,
            homeMarket,
            prevPeopleManagerName,
            accountStatus,
            rejectReason,
          }) => (
            <UserCard
              key={email}
              email={email}
              firstName={firstName}
              preferredName={preferredName}
              lastName={lastName}
              slalomGlobalTeam={slalomGlobalTeam}
              slalomCapability={slalomCapability}
              homeMarket={homeMarket}
              prevPeopleManagerName={prevPeopleManagerName}
              accountStatus={accountStatus}
              rejectReason={rejectReason}
            />
          )
        );
      }
      return <h3 className="not-found-message">No result found</h3>;
    }
    return null;
  };

  return (
    <div className="admin-dashboard">
      <h3 className="dashboard-label">Alumni Administrator Dashboard</h3>
      <div className="filters">
        <h4 className="search-by-label">Search by</h4>
        <div className="search-by-container">
          <EmailSearch onSearchClick={handleSearchClick} />
        </div>
        <h4 className="filter-by-label">Filter by</h4>
        <div className="filters-container">
          <MultiSelect
            className="multi-select"
            onChange={(event: SelectChangeEvent<string[]>) =>
              handleFilterChange(event, 'globalTeams')
            }
            options={globalTeams}
            onDelete={chipDeleteGlobalTeams}
            labelText="Global Team"
            userDataArray={filters.globalTeams}
            width={menuWidth}
            chipsVisible={2}
          />
          <MultiSelect
            className="multi-select"
            onChange={(event: SelectChangeEvent<string[]>) =>
              handleFilterChange(event, 'markets')
            }
            options={markets}
            onDelete={chipDeleteMarkets}
            labelText="Home Market Location"
            userDataArray={filters.markets}
            width={menuWidth}
          />
          <MultiSelect
            className="multi-select"
            onChange={(event: SelectChangeEvent<string[]>) =>
              handleFilterChange(event, 'capabilities')
            }
            options={capabilities}
            onDelete={chipDeleteCapabilities}
            labelText="Capability"
            userDataArray={filters.capabilities}
            width={menuWidth}
          />
          <MultiSelect
            className="multi-select"
            onChange={(event: SelectChangeEvent<string[]>) =>
              handleFilterChange(event, 'accountStatuses')
            }
            options={accountStatuses}
            onDelete={chipDeleteAccountStatuses}
            labelText="Account Status"
            userDataArray={filters.accountStatuses}
            width={menuWidth}
            chipsVisible={2}
          />
        </div>
      </div>
      <div data-testid="worklist" className="worklist">
        {renderUserCard()}
      </div>
    </div>
  );
};
export default AdminDashboard;
