import { useState, useEffect } from 'react';
import polygon from '../../assets/polygon.svg';
import rectangle from '../../assets/rectangle.svg';
import SolidButton from '../common/solid-button/solid-button';
import { ArticlePreviewDetails } from './article-preview-array';
import './article-preview.scss';

interface ArticlePreviewProps {
  article: ArticlePreviewDetails;
  imageOnLeft: boolean;
}

const ArticlePreview: React.FC<ArticlePreviewProps> = function ({
  article,
  imageOnLeft,
}: ArticlePreviewProps) {
  const {
    publishDate,
    title,
    previewParagraph,
    imageUrl,
    articleLink,
  }: ArticlePreviewDetails = article;

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  const updateMedia = () => {
    setIsSmallScreen(window.innerWidth < 1200);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const findOutMore = (url: string) => {
    window.open(url, '_blank');
  };

  const articleImage = (
    <div
      className={`grid-col6 grid-colsm12 article-image-container ${
        isSmallScreen && 'small-screen'
      }`}
    >
      <a href={articleLink}>
        <img className="article-image" src={imageUrl} alt="img" />
      </a>
    </div>
  );

  const leftImage = () => {
    if (imageOnLeft || isSmallScreen) {
      return articleImage;
    }
    return null;
  };

  const rightImage = () => {
    if (!imageOnLeft && !isSmallScreen) {
      return articleImage;
    }
    return null;
  };

  return (
    <div className="article-preview" data-testid="article-preview">
      {leftImage()}
      <section className="article-preview-details grid-col6 grid-colsm12">
        <div className="publish-date-container">
          <img src={rectangle} alt="rectangle" className="rectangle-icon" />
          <p className="publish-date">{publishDate}</p>
        </div>
        <h2 className="article-title">{title}</h2>
        <div className="article grid-col4">
          <p className="preview-paragraph" data-testid="preview-paragraph">
            {previewParagraph}
          </p>
          <div className="find-out-more">
            <img src={polygon} alt="polygon" className="polygon-icon" />
            <SolidButton
              className="more-button"
              buttonTheme="text-only"
              buttonText="FIND OUT MORE"
              onClick={() => findOutMore(articleLink)}
            />
          </div>
        </div>
      </section>
      {rightImage()}
    </div>
  );
};

export default ArticlePreview;
