import testFacts from '../../components/__mocks__/TestFunFacts';
import ContactInfoSection from '../../components/contact-info/contact-info-section/contact-info-section';
import contactInfoSectionArray from '../../components/contact-info/contact-info-section/contact-info-section-array';
import { teaserProps } from '../../components/content-teaser/Content-Teasers-Array';
import { ContentTeaserContainer } from '../../components/content-teaser/ContentTeaser';
import FeaturesSection from '../../components/features-section/FeaturesSection';
import { FunFacts } from '../../components/fun-facts/fun-facts';
import Hero from '../../components/hero-landing/hero-landing';
import '../../css/breakpoint-layout.scss';

const LandingPage: React.FC = function () {
  return (
    <div>
      <Hero />
      <FeaturesSection />
      <ContentTeaserContainer teaserProps={teaserProps} />
      <FunFacts facts={testFacts} />
      <ContactInfoSection contactInfo={contactInfoSectionArray} />
    </div>
  );
};

export default LandingPage;
