import './hero-experience.scss';
import { getAttributeFromToken } from 'services/auth.service';

const HeroExperience: Function = function () {
  return (
    <div className="hero-experience">
      <h2 className="welcome" data-testid="welcome">
        Welcome, {getAttributeFromToken('given_name')}
      </h2>
      <p className="description hero" data-testid="description">
        This is where you can explore around. You can find Slalom’s upcoming
        events, recent hightlights, articles, videos, and more. You can also
        connect with your past coworkers.
      </p>
    </div>
  );
};

export default HeroExperience;
