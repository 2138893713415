import { KeyboardArrowUpRounded } from '@mui/icons-material';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { useState } from 'react';
import './slalom-info-dropdown.scss';

interface DropDownMenuProps {
  inputTitle: string;
  options: string[];
  formItemValue: string;
  setFormItemValue: (text: string) => void;
}

const SlalomInfoDropdown: React.FC<DropDownMenuProps> = function ({
  inputTitle,
  options,
  formItemValue,
  setFormItemValue,
}: DropDownMenuProps) {
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const handleChange = (event: SelectChangeEvent): void => {
    setFormItemValue(event.target.value);
  };

  return (
    <FormControl fullWidth className="drop-down-form">
      <InputLabel
        id={inputTitle}
        className={`label ${formItemValue && 'selected'} ${
          !focus && 'blurred'
        } ${hover && 'hover'}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {inputTitle}
      </InputLabel>
      <Select
        labelId={inputTitle}
        id={inputTitle}
        value={formItemValue}
        onChange={handleChange}
        label={inputTitle}
        className={`dropdown-select  ${formItemValue && 'selected'} ${
          !focus && 'blurred'
        } ${hover && 'hover'}`}
        open={open}
        onClose={() => {
          setFocus(false);
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
          setFocus(true);
        }}
        data-testid="dropdown-select"
        IconComponent={KeyboardArrowUpRounded}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            value={item}
            className="item"
            data-testid="dropdown-menu-item"
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SlalomInfoDropdown;
