import { FunctionComponent } from 'react';
import './outlined-button.scss';
import leftCaret from '../../../assets/left-caret.svg';
import rightCaret from '../../../assets/right-caret.svg';

export type OutlinedButtonTheme = 'outlined_light' | 'outlined_dark';
export type OutlinedButtonIcons = 'left-caret' | 'right-caret';

interface OutlinedButtonProps {
  buttonText?: string;
  onClick: () => void;
  theme?: OutlinedButtonTheme;
  className?: string;
  rightIconType?: OutlinedButtonIcons;
  isIconOnly?: boolean;
  disabled?: boolean;
}

function returnSrc(iconType: OutlinedButtonIcons): string {
  switch (iconType) {
    case 'left-caret':
      return leftCaret;
    case 'right-caret':
      return rightCaret;
    default:
      return '';
  }
}

/*
    Outlined button with a transparent background and changes to white when hovered over.
    specify the buttonText and onCLick function when calling.
*/
export const OutlinedButton: FunctionComponent<OutlinedButtonProps> =
  function ({
    buttonText = '',
    onClick,
    theme = 'outlined_light',
    className = '',
    rightIconType = undefined,
    isIconOnly = false,
    disabled = false,
  }) {
    return (
      <button
        type="button"
        onClick={onClick}
        onKeyDown={onClick}
        className={`outlined_button ${theme} ${className} ${
          isIconOnly ? 'icon-only' : ''
        }`}
        disabled={disabled}
      >
        {buttonText}
        {rightIconType && (
          <div className={isIconOnly ? 'icon-box' : ''}>
            <img className="right-icon" src={returnSrc(rightIconType)} alt="" />
          </div>
        )}
      </button>
    );
  };

export default OutlinedButton;
