import instagramLogo from './assets/instagram-icon.svg';
import linkedInLogo from './assets/linkedin-icon.svg';
import mediumLogo from './assets/medium-blog-icon.svg';
import twitterLogo from './assets/twitter-icon.svg';

const FooterSocials: React.FC = function () {
  function clickInstagram() {
    window.open('https://www.instagram.com/slalombuild/?hl=en', '_blank');
  }
  function clickLinkedIn() {
    window.open('https://ca.linkedin.com/company/slalom-build', '_blank');
  }
  function clickTwitter() {
    window.open('https://twitter.com/slalombuild', '_blank');
  }
  function clickMedium() {
    window.open('https://medium.com/slalom-build', '_blank');
  }

  return (
    <nav className="socials">
      <button
        className="icon-button"
        data-testid="instagram"
        type="button"
        onClick={clickInstagram}
      >
        <img
          src={instagramLogo}
          alt="Instagram"
          aria-label="instagram"
          className="social-icon"
        />
      </button>
      <button
        className="icon-button"
        data-testid="linkedIn"
        type="button"
        onClick={clickLinkedIn}
      >
        <img
          src={linkedInLogo}
          alt="LinkedIn"
          aria-label="linkedIn"
          className="social-icon"
        />
      </button>
      <button
        className="icon-button"
        data-testid="twitter"
        type="button"
        onClick={clickTwitter}
      >
        <img
          src={twitterLogo}
          alt="Twitter"
          aria-label="twitter"
          className="social-icon"
        />
      </button>
      <button
        className="icon-button"
        data-testid="medium"
        type="button"
        onClick={clickMedium}
      >
        <img
          src={mediumLogo}
          alt="Medium"
          aria-label="medium"
          className="social-icon"
        />
      </button>
    </nav>
  );
};

export default FooterSocials;
