import { useState, useEffect, useRef } from 'react';

export const useWindowDimensions: () => number = function () {
  const [windowDimensions, setWindowDimensions] = useState(window.innerWidth);

  // rerenders everytime there is a change to the width of the window
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const useComponentVisible = function () {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return { ref, open, setOpen };
};
