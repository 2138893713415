import React from 'react';
import FeatureCard from '../feature-card/FeatureCard';
import featuresSection from './features-section-array';
import './features-section.scss';

const FeaturesSection: React.FC = function () {
  const renderFeatures = () =>
    featuresSection.map(({ icon, title, text }) => (
      <FeatureCard key={title} icon={icon} title={title} text={text} />
    ));

  return (
    <section className="FeaturesSection">
      {/* Header */}
      <div className="Header">
        <h2>
          Slalom Relay keeps you connected with the best of Slalom: its people.
        </h2>
      </div>

      {/* Cards */}
      <div className="Feature-Card-Container">{renderFeatures()}</div>
    </section>
  );
};

export default FeaturesSection;
