import { BaseResponse } from '../interfaces/BaseResponse.interface';
import {
  AccountStatus,
  UserData,
  UserResponse,
} from '../registration-context/userConstants';
import client from './http.service';

export type GetUsersResponse = {
  data: Partial<UserData>[];
  page: number;
  totalItems: number;
};

export const getUsers = async (
  pageNum: number
): Promise<GetUsersResponse | Error> => {
  try {
    const { data } = await client.get('/users', {
      params: { pageNumber: pageNum },
    });
    return data.result;
  } catch (err: unknown) {
    if (err instanceof Error) {
      return err as Error;
    }
    return new Error();
  }
};

export const createUser = async (
  userData: UserData
): Promise<Partial<UserData> | Error> => {
  try {
    const { data } = await client.post('/user', userData);
    return data;
  } catch (err: unknown) {
    if (err instanceof Error) {
      return err as Error;
    }
    return new Error();
  }
};

export const updateUserStatus = async (
  email: string,
  status: AccountStatus
): Promise<Partial<UserData> | Error> => {
  const body = {
    email,
    status,
  };
  const { result }: BaseResponse<UserResponse> = await client.put(
    '/user/status',
    body
  );
  return result;
};

export default { getUsers, createUser, updateUserStatus };
