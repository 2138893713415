import EditIcon from '@mui/icons-material/Edit';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuIcon from '@mui/icons-material/Menu';
import TwitterIcon from '@mui/icons-material/Twitter';
import './icons.scss';
import BriefcaseIcon from '@mui/icons-material/WorkOutline';
import React from 'react';

type SocialIcon =
  | 'facebook'
  | 'instagram'
  | 'twitter'
  | 'linkedIn'
  | 'locationOnOutlined'
  | 'localPhone'
  | 'mailOutlined'
  | 'briefcase'
  | 'menu'
  | 'edit';

type IconProps = {
  iconName: SocialIcon;
  background?: boolean;
  className?: string;
  onClick?: () => void;
};

const Icon: React.FC<IconProps> = function ({
  iconName,
  background = true,
  className = 'social-icon',
  onClick = () => {},
}) {
  const renderIcon = () => {
    switch (iconName) {
      case 'menu':
        return <MenuIcon className="menuIcon" />;
      case 'briefcase':
        return <BriefcaseIcon className="primaryIcon" />;
      case 'mailOutlined':
        return <MailOutlineIcon className="primaryIcon" />;
      case 'localPhone':
        return <LocalPhoneIcon className="primaryIcon" />;
      case 'locationOnOutlined':
        return <LocationOnOutlinedIcon className="primaryIcon" />;
      case 'facebook':
        return <FacebookIcon className={className} onClick={onClick} />;
      case 'instagram':
        return <InstagramIcon className={className} onClick={onClick} />;
      case 'linkedIn':
        return <LinkedInIcon className={className} onClick={onClick} />;
      case 'twitter':
        return <TwitterIcon className={className} onClick={onClick} />;
      case 'edit':
        return <EditIcon className={className} onClick={onClick} />;
      default:
        return null;
    }
  };
  return (
    <div className={background ? 'iconContainer' : ''}>{renderIcon()}</div>
  );
};

export default Icon;
