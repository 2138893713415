import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminDashboard from '../../components/admin-dashboard/admin-dashboard';
import {
  accountStatuses,
  slalomCapabilities,
  slalomGlobalTeams,
  slalomMarkets,
} from '../../registration-context/userConstants';
import { ADMIN_DASHBOARD } from '../../routeConstants';
import './admin-page.scss';

const AdminPage: FC = function () {
  return (
    <div className="admin-base">
      <Routes>
        {/* Replace Default path with Login once implemented */}
        <Route path="" element={<Navigate to={ADMIN_DASHBOARD} />} />
        <Route
          path={ADMIN_DASHBOARD}
          element={
            <AdminDashboard
              globalTeams={slalomGlobalTeams}
              markets={slalomMarkets}
              capabilities={slalomCapabilities}
              accountStatuses={accountStatuses}
            />
          }
        />
      </Routes>
    </div>
  );
};
export default AdminPage;
