import { ContentTeaserProps } from './ContentTeaser';

/**
 * Array of the content teasers printed on the Landing Page
 * Note: there should only be 3 teasers on the page
 */
export const teaserProps: ContentTeaserProps[] = [
  {
    img: 'https://www.edifybuilds.com/wp-content/uploads/2020/04/Edify-Slalom-Interiors-5-06-Reception-55741-FINAL-LR-1-1200x795.jpg',
    title: 'Title',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\
           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,\
           quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    date: 'June 10, 2022',
    link: 'https://www.slalombuild.com/',
  },
  {
    img: 'https://www.edifybuilds.com/wp-content/uploads/2020/04/Edify-Slalom-Interiors-5-06-Reception-55741-FINAL-LR-1-1200x795.jpg',
    title: 'Title',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\
           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, \
           quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    date: 'June 10, 2022',
    link: 'https://www.slalombuild.com/',
  },
  {
    img: 'https://www.edifybuilds.com/wp-content/uploads/2020/04/Edify-Slalom-Interiors-5-06-Reception-55741-FINAL-LR-1-1200x795.jpg',
    title: 'Title',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\
           eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, \
           quis nostrud exercitation ullamco laboris nisi ut aliquip.',
    date: 'June 10, 2022',
    link: 'https://www.slalombuild.com/',
  },
];
export default teaserProps;
