import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { SelectChangeEvent } from '@mui/material/Select';
import { ChangeEvent, FC, useState } from 'react';
import DropDown from '../../common/dropdown-menu/dropdown-menu';
import Custom from './custom-pronouns/custom-pronouns';
import './pronouns.scss';

export const PronounDropdownOptions = ['She', 'He', 'They'];
export const PronounDropdownOptionsPt2 = ['Her', 'Him', 'Them'];
interface PronounSelectProps {
  onPronounOptionChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDropdownChange: (event: SelectChangeEvent) => void;
  onCustomChange: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultSelected: string;
  pronoun1Value: string;
  pronoun2Value: string;
  customValue: string;
}

export const PronounSelect: FC<PronounSelectProps> = function ({
  onPronounOptionChange,
  onDropdownChange,
  onCustomChange,
  defaultSelected,
  pronoun1Value,
  pronoun2Value,
  customValue,
}: PronounSelectProps) {
  const [dropdown, setDropdown] = useState(defaultSelected !== 'dropdown');
  const [custom, setCustom] = useState(defaultSelected !== 'custom');
  const [notDisclosed, setNotDisclosed] = useState(
    defaultSelected !== 'notDisclosed'
  );

  const setEnabled = (selected: string) => {
    switch (selected) {
      case 'dropdown': {
        setDropdown(false);
        setCustom(true);
        setNotDisclosed(true);
        break;
      }
      case 'custom': {
        setDropdown(true);
        setCustom(false);
        setNotDisclosed(true);
        break;
      }
      case 'notDisclosed': {
        setDropdown(true);
        setCustom(true);
        setNotDisclosed(false);
        break;
      }
      default: {
        setDropdown(false);
        setCustom(true);
        setNotDisclosed(true);
        break;
      }
    }
  };
  return (
    <FormControl>
      <h4 className="form-label">Pronouns</h4>
      <RadioGroup
        name="radio-buttons-group"
        onChange={onPronounOptionChange}
        data-testid="radio-group"
        className="radio-group"
        defaultValue="dropdown"
        value={defaultSelected}
      >
        <div className="options-container">
          <FormControlLabel
            value="dropdown"
            control={
              <Radio disableRipple onClick={() => setEnabled('dropdown')} />
            }
            checked={!dropdown}
            className="form-control-label"
            data-testid="dropdown-option"
            label={
              <div className="dropdown-container">
                <DropDown
                  placeholder="Pronouns Pt.1*"
                  items={PronounDropdownOptions}
                  disabled={dropdown}
                  className={`dropdown ${dropdown ? 'disabled' : ''}`}
                  onChange={onDropdownChange}
                  value={pronoun1Value}
                />
                <p className="slash-container">/</p>
                <DropDown
                  placeholder="Pronouns Pt.2*"
                  items={PronounDropdownOptionsPt2}
                  disabled={dropdown}
                  className={`dropdown ${dropdown ? 'disabled' : ''}`}
                  onChange={onDropdownChange}
                  value={pronoun2Value}
                />
              </div>
            }
          />
          <FormControlLabel
            checked={!custom}
            value="custom"
            control={
              <Radio disableRipple onClick={() => setEnabled('custom')} />
            }
            data-testid="custom"
            label={
              <Custom
                disabled={custom}
                onChange={onCustomChange}
                value={customValue}
                onClick={() => setEnabled('custom')}
              />
            }
          />
          <FormControlLabel
            checked={!notDisclosed}
            value="notDisclosed"
            className="not-disclosed"
            control={
              <Radio disableRipple onClick={() => setEnabled('notDisclosed')} />
            }
            data-testid="not-disclosed"
            label={
              <p className={notDisclosed ? 'unchecked' : 'checked'}>
                Prefer not to disclose
              </p>
            }
          />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default PronounSelect;
