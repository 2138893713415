import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import BlogsAndArticles from '../../components/blogs-and-articles/blogs-and-articles';
import MyEvents from '../../components/my-events/my-events';
import MyRelay from '../../components/my-relay/my-relay';
import Videos from '../../components/videos/videos';
import * as RouteConstants from '../../routeConstants';

const AlumniPage: FC = function () {
  return (
    <div className="alumni-base">
      <Routes>
        <Route path="" element={<MyRelay />} />
        <Route path={RouteConstants.ALUMNI_MY_EVENTS} element={<MyEvents />} />
        <Route path={RouteConstants.ALUMNI_VIDEOS} element={<Videos />} />
        <Route
          path={RouteConstants.BLOGS_AND_ARTICLES}
          element={<BlogsAndArticles />}
        />
      </Routes>
    </div>
  );
};
export default AlumniPage;
