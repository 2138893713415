export const LANDING_PAGE: string = '/';
export const ALUMNI_LANDING: string = '/my-relay/*';
export const ALUMNI: string = '/my-relay/';
export const ALUMNI_VIDEOS: string = 'videos';
export const ALUMNI_MY_EVENTS: string = 'my-events';
export const BLOGS_AND_ARTICLES: string = 'blogs-and-articles';
// asterisk required to find nested paths
export const REGISTRATION_LANDING: string = '/registration/*';
// required for absolute paths
export const REGISTRATION: string = '/registration/';
export const REGISTRATION_BASIC_INFO: string = 'basic-info';
export const REGISTRATION_SLALOM_INFO: string = 'slalom-info';
export const REGISTRATION_PROFILE_INFO: string = 'profile-info';
export const REGISTRATION_NOTIFICATION_INFO: string = 'notification-info';
export const REGISTRATION_COMPLETE: string = 'complete';
export const REGISTRATION_SUCCESSFUL: string = 'successful';
export const REGISTRATION_UNSUCCESSFUL: string = 'unsuccessful';
export const ADMIN_LANDING: string = '/admin/*';
export const ADMIN: string = '/admin/';
export const ADMIN_DASHBOARD: string = 'dashboard';

export const LOGIN_LANDING: string = '/login/*';
export const LOGIN: string = '/login/';
export const LOGIN_RESET_PASSWORD: string = 'reset-password';
export const ADMIN_LOGIN: string = '/admin/login/';
