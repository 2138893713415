import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { VideoDetails } from './videos-array';
import './videos-list.scss';

interface VideoProps {
  videoArray: VideoDetails[];
}

const VideosList: React.FC<VideoProps> = function ({ videoArray }: VideoProps) {
  return (
    <div className="videos-list-container">
      {videoArray.map((video: VideoDetails) => (
        <div key={video.title} className="videos-list-item">
          <a href={video.youtubeUrl} target="_blank" rel="noreferrer">
            <div
              className="video-list-image-container"
              data-testid="video-list-clickable"
            >
              <img
                className="video-list-image"
                src={video.videoUrl}
                alt={`${video.title} video thumbnail`}
              />
              <PlayCircleIcon
                className="video-list-playbutton"
                aria-label="video-playbutton"
              />
              <div className="video-list-timestamp">{video.timestamp}</div>
            </div>
          </a>
          <div className="video-list-date">{video.date}</div>
          <h4 className="video-list-title">{video.title}</h4>
        </div>
      ))}
    </div>
  );
};

export default VideosList;
