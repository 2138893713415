import CheckIcon from '@mui/icons-material/Check';
import { FormControlLabel, FormGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { UserData } from '../../registration-context/userConstants';
import UserRegistrationFormContext from '../../registration-context/UserRegistrationFormContext';
import { REGISTRATION, REGISTRATION_COMPLETE } from '../../routeConstants';
import { OutlinedButton } from '../common/outlined-button/outlined-button';
import { SolidButton } from '../common/solid-button/solid-button';
import './registration-notification-info.scss';

const RegistrationNotificationInfo: React.FC = function () {
  const navigate: NavigateFunction = useNavigate();
  const navigateToRegistrationComplete = () => {
    navigate(`${REGISTRATION}${REGISTRATION_COMPLETE}`);
  };

  const {
    userData: state,
    updateUserData: dispatch,
    submit,
  } = useContext(UserRegistrationFormContext);
  const [userData, setUserData] = useState<UserData>(state);

  const navigateBackOne = () => {
    dispatch(userData);
    navigate(-1);
  };
  const handleChange =
    (prop: keyof UserData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserData({ ...userData, [prop]: event.target.checked });
    };

  const handleOnClick = async () => {
    dispatch(userData);
    const result = await submit();
    if (typeof result === 'string') {
      // eslint-disable-next-line
      console.error(result);
    } else {
      navigateToRegistrationComplete();
    }
  };

  const label = (
    <span className="linked-label">
      I agree to Slalom’s
      <a
        href="https://www.slalom.com/terms-of-use"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Terms of Use
      </a>{' '}
      and
      <a
        href="https://www.slalom.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Privacy Policy
      </a>
      . <sup className="exponent">*</sup>
    </span>
  );

  return (
    <div className="registration-info">
      <section className="info-box">
        <p className="registration-sett-step bold">Step 4 of 4</p>
        <h3 className="reg-info-title">Notification settings & permissions</h3>
        <p className="body-text">
          Slalom respect the privacy of your personal information and, as such,
          make every effort to ensure your information is protected and remains
          private. Define your privacy preferences and notification settings
          here.
        </p>
        <FormGroup className="checkboxes">
          <FormControlLabel
            control={
              <Checkbox
                checked={userData.isEnrolledNotifications}
                onChange={handleChange('isEnrolledNotifications')}
                checkedIcon={<CheckIcon />}
              />
            }
            label="I want to receive emails."
          />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={userData.isEmailExposedAlums}
                onChange={handleChange('isTermsConditionsAccepted')}
                checkedIcon={<CheckIcon />}
              />
            }
            label={label}
          />
        </FormGroup>
        <div className="button-container">
          <OutlinedButton
            className="back-button"
            theme="outlined_dark"
            onClick={navigateBackOne}
            rightIconType="left-caret"
            isIconOnly
          />
          <SolidButton
            className="finish-button"
            buttonText="Finish"
            disabled={!userData.isTermsConditionsAccepted}
            onClick={handleOnClick}
            isWhiteBackground
          />
        </div>
      </section>
    </div>
  );
};

export default RegistrationNotificationInfo;
