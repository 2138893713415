import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, { FunctionComponent } from 'react';
import info from '../../../assets/info.svg';
import BasicInfoState from './basic-info-state';
import './contact-info.scss';
import SlalomTooltip from './tooltitp';

interface PropsAccountSetup {
  email: string;
  onChange: (
    prop: keyof BasicInfoState
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  status: string;
}

const ContactInfo: FunctionComponent<PropsAccountSetup> = function ({
  email,
  onChange,
  status,
}) {
  return (
    <div className="contact-info">
      <h4 className="contact-title">Contact/Sign in email</h4>
      <div className="form-field">
        <Stack direction="row" alignItems="center">
          <FormControl fullWidth className="form-control-field">
            <TextField
              required
              fullWidth
              data-testid="email-field"
              className={`outlined-basic input-field ${
                email && 'has-input'
              } email ${status}`}
              label="Email address"
              value={email}
              onChange={onChange('email')}
            />
          </FormControl>
          <SlalomTooltip
            title="Use an email we can reach you at!"
            data-testid="email-tooltip"
            placement="top"
          >
            <img className="info-icon" src={info} alt="" />
          </SlalomTooltip>
        </Stack>
      </div>
    </div>
  );
};

export default ContactInfo;
