import { FeatureCardProps } from '../feature-card/FeatureCard';

const featuresSection: FeatureCardProps[] = [
  {
    icon: 'briefcase',
    title: 'Exclusive Events & Content',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    icon: 'briefcase',
    title: 'Referrals & Careers',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    icon: 'briefcase',
    title: 'Helpful Connections',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

export default featuresSection;
