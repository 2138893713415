export interface ArticlePreviewDetails {
  publishDate: string;
  title: string;
  previewParagraph: string;
  imageUrl: string;
  articleLink: string;
}

export const articlePreviewArray: ArticlePreviewDetails[] = [
  {
    publishDate: 'Jul 21, 2022',
    title: 'Responsible AI: Promoting Diversity in the Film Industry',
    previewParagraph:
      'As movie trends go, most of us have been exposed to this phrase. It has a certain pull that captures our \
      imaginations and conveys an inspiring sentiment, appealing to a very human side of the audience. How, then, can we apply \
      movie to something that isn’t human?',
    imageUrl: '/article-assets/B1.png',
    articleLink:
      'https://medium.com/slalom-data-ai/responsible-ai-promoting-diversity-in-the-film-industry-b298ab0b119a?\
      source=friends_link&sk=8c03fb49d07662bc310cde9b75a46cf6',
  },
  {
    publishDate: 'Jul 5, 2022',
    title: 'Slalom Wins Multiple Microsoft Canada Impact Awards',
    previewParagraph:
      ' Slalom, a global consulting firm helping people and organizations dream bigger, move faster, and build better \
      tomorrows, is honoured to announce it has won three 2022 Microsoft Canada Impact Awards for Analytics, Co-Sell, and Public Sector.',
    imageUrl: '/article-assets/B2.png',
    articleLink:
      'https://go.slalom.com/slalomwinsmicrosoftimpactawards2022?utm_campaign=c225786a-6536-47ed-964c-8adc5dc7f03f\
      &utm_medium=dynamicsignal&utm_source=None&utm_term=0',
  },
  {
    publishDate: 'Jun 9, 2022',
    title: 'Slalom Boston is Hiring Organizational Strategists!',
    previewParagraph:
      'Our Organizational Effectiveness Team is Growing! Do you have a passion for transforming through people-focused \
      strategies? Reach out to Katie Bhattacharya (catherineb@slalom.com) or apply here: https://jobs.slalom.com/#/post/a0h1R00000BXXBqQAP',
    imageUrl: '/article-assets/B3.png',
    articleLink:
      'https://jobs.slalom.com/?utm_campaign=6ff384d2-5908-450c-b305-958e471995e5&utm_medium=dynamicsignal&utm_source=LinkedIn\
      &utm_term=131143#/post/a0h1R00000BXXBqQAP',
  },
];

export const articleArray: ArticlePreviewDetails[] = [
  {
    publishDate: 'Jul 21, 2022',
    title: 'Responsible AI: Promoting Diversity in the Film Industry',
    previewParagraph:
      'As movie trends go, most of us have been exposed to this phrase. It has a certain pull that captures our imaginations \
      and conveys an inspiring sentiment, appealing to a very human side of the audience. How, then, can we apply movie to \
      something that isn’t human?',
    imageUrl: '/article-assets/B1.png',
    articleLink:
      'https://medium.com/slalom-data-ai/responsible-ai-promoting-diversity-in-the-film-industry-b298ab0b119a?\
      source=friends_link&sk=8c03fb49d07662bc310cde9b75a46cf6',
  },
  {
    publishDate: 'Jul 5, 2022',
    title: 'Slalom Wins Multiple Microsoft Canada Impact Awards',
    previewParagraph:
      ' Slalom, a global consulting firm helping people and organizations dream bigger, move faster, and build better \
      tomorrows, is honoured to announce it has won three 2022 Microsoft Canada Impact Awards for Analytics, Co-Sell, and Public Sector.',
    imageUrl: '/article-assets/B2.png',
    articleLink:
      'https://go.slalom.com/slalomwinsmicrosoftimpactawards2022?utm_campaign=c225786a-6536-47ed-964c-8adc5dc7f03f\
      &utm_medium=dynamicsignal&utm_source=None&utm_term=0',
  },
  {
    publishDate: 'Jun 9, 2022',
    title: 'Slalom Boston is Hiring Organizational Strategists!',
    previewParagraph:
      'Our Organizational Effectiveness Team is Growing! Do you have a passion for transforming through people-focused strategies? Reach \
      out to Katie Bhattacharya (catherineb@slalom.com) or apply here: https://jobs.slalom.com/#/post/a0h1R00000BXXBqQAP',
    imageUrl: '/article-assets/B3.png',
    articleLink:
      'https://jobs.slalom.com/?utm_campaign=6ff384d2-5908-450c-b305-958e471995e5&utm_medium=dynamicsignal\
      &utm_source=LinkedIn&utm_term=131143#/post/a0h1R00000BXXBqQAP',
  },
  {
    publishDate: 'Jun 1, 2022',
    title: 'Shaping the Chicago Experience by Bringing Big Ideas to Life',
    previewParagraph:
      'Watch Slalom, Chicago Ideas, and the Chicago Cubs discuss the evolution of our city’s experience and uncover why \
      Chicago is uniquely positioned for greatness.',
    imageUrl: '/article-assets/B4.png',
    articleLink:
      'https://go.slalom.com/Big-Ideas-Chicago?utm_campaign=a44e4888-5f31-4b10-89b0-18305385c3e5&utm_medium=dynamicsignal\
      &utm_source=None&utm_term=0',
  },
  {
    publishDate: 'May 23, 2022',
    title: 'Architecting Data Science Applications on Snowflake',
    previewParagraph:
      'An approach to addressing the challenges of the machine learning lifecycle and designing enterprise-scale\
    solutions using the Snowflake and AWS platforms.',
    imageUrl: '/article-assets/B5.png',
    articleLink:
      'https://medium.com/slalom-technology/architecting-data-science-applications-on-snowflake-dce906f1e387',
  },
];
