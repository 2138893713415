import UserRole from 'enums/userRole.enums';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import video from '../../../assets/homepage-hero-v8.mp4';
import useAuth from '../../../hooks/useAuth';
import { emailRegExp, LoginData } from '../../../login-context/loginConstants';
import UserLoginFormContext from '../../../login-context/UserLoginFormContext';
import {
  ALUMNI,
  LOGIN_RESET_PASSWORD,
  ADMIN,
  ADMIN_LOGIN,
} from '../../../routeConstants';
import { SolidButton } from '../../common/solid-button/solid-button';
import { TextInputField } from '../../common/text-input-field/text-input-field';
import { Password, PasswordStatus } from '../../password/password';
import './login-landing.scss';

const LoginLanding: FC = function () {
  const { loginData: state, updateLoginData: dispatch } =
    useContext(UserLoginFormContext);
  const [loginData, setLoginData] = useState<LoginData>(state);
  const { loginUser, isResetRequired, updateIsAuthenticated } = useAuth();
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation().pathname;
  const login = async () => {
    // api request to backend login endpoint
    // upon first login navigate to reset password
    dispatch(loginData);
    // logic to handle admin or alumni login
    if (location === ADMIN_LOGIN) {
      const success = await loginUser(
        loginData.email,
        loginData.password,
        UserRole.Admin
      );
      if (success) {
        navigate(ADMIN);
        updateIsAuthenticated();
      }
    } else {
      const success = await loginUser(loginData.email, loginData.password);
      if (success) {
        navigate(ALUMNI);
        updateIsAuthenticated();
      } else if (isResetRequired()) {
        navigate(LOGIN_RESET_PASSWORD);
      }
    }
  };

  const handleChange =
    (prop: keyof LoginData) => (event: ChangeEvent<HTMLInputElement>) => {
      setLoginData({ ...loginData, [prop]: event.target.value });
    };

  const [emailValid, setEmailValid] = useState(false);
  useEffect(() => {
    if (loginData.email !== '') {
      setEmailValid(emailRegExp.test(loginData.email));
    }
  }, [loginData.email]);

  const enableButton = loginData.email && loginData.password && emailValid;

  return (
    <div className="login-landing">
      <video autoPlay muted loop className="login-hero-video">
        <source src={video} type="video/mp4" />
      </video>
      <section className="login-start">
        <h3 className="title">Welcome to Slalom Relay!</h3>
        <TextInputField
          className="email"
          value={loginData.email}
          label="Email address"
          ariaLabel="email"
          onChange={handleChange('email')}
        />
        <Password
          onChange={handleChange}
          password={loginData.password}
          field="password"
          label="Password"
          status={PasswordStatus.INITIAL}
        />
        <SolidButton
          className="login-button"
          disabled={!enableButton}
          buttonText="Log in"
          buttonTheme="primary"
          rightIconType="right-arrow"
          testId="login-button"
          onClick={login}
        />
      </section>
    </div>
  );
};

export default LoginLanding;
