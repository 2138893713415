import { NavigateFunction, useNavigate } from 'react-router-dom';
import { LANDING_PAGE } from '../../routeConstants';
import { OutlinedButton } from '../common/outlined-button/outlined-button';
import { SolidButton } from '../common/solid-button/solid-button';
import './registration-unsuccessful.scss';

const RegistrationUnsuccessful: React.FC = function () {
  const navigate: NavigateFunction = useNavigate();
  const navigateToHome = () => {
    navigate(LANDING_PAGE);
  };
  return (
    <div className="registration-info">
      <section className="info-box">
        <div className="button-container">
          <SolidButton
            className="register-button"
            buttonText="Register"
            onClick={navigateToHome}
            isWhiteBackground
          />
          <OutlinedButton
            className="home-button"
            buttonText="Return to Home"
            theme="outlined_dark"
            onClick={navigateToHome}
          />
        </div>
      </section>
    </div>
  );
};

export default RegistrationUnsuccessful;
