import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import * as RouteConstants from '../../routeConstants';
import OutlinedButton from '../common/outlined-button/outlined-button';
import './my-relay-video.scss';
import { VideoDetails, videosArray } from './videos-array';
import VideosList from './videos-list';

interface MainVideoProps {
  mainVideoArray: VideoDetails[];
}

const MyRelayVideo: React.FC<MainVideoProps> = function ({
  mainVideoArray,
}: MainVideoProps) {
  const navigate: NavigateFunction = useNavigate();

  return (
    <div className="videos-container">
      <div className="items-container">
        <div>
          {mainVideoArray.map((mainVideo) => (
            <div key={mainVideo.title} className="text-container">
              <span className="date">{mainVideo.date}</span>
              <h2 className="title-text">{mainVideo.title}</h2>
              <div className="play-button-container">
                <a href={mainVideo.youtubeUrl} target="_blank" rel="noreferrer">
                  <PlayCircleIcon />
                </a>
                <span className="background-timestamp">
                  {mainVideo.timestamp}
                </span>
              </div>
            </div>
          ))}
          <div className="video-list">
            <VideosList videoArray={videosArray} />
          </div>
        </div>
        <OutlinedButton
          buttonText="See all videos"
          theme="outlined_light"
          className="more-videos-button"
          onClick={() => {
            navigate(RouteConstants.ALUMNI + RouteConstants.ALUMNI_VIDEOS);
          }}
        />
      </div>
    </div>
  );
};

export default MyRelayVideo;
