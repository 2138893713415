import { Route, Routes } from 'react-router-dom';
import LoginLanding from '../../components/login/login-landing/login-landing';
import LoginResetPassword from '../../components/login/login-reset-password/login-reset-password';
import UserLoginFormProvider from '../../login-context/UserLoginFormProvider';
import * as RouteConstants from '../../routeConstants';
import './login-page.scss';

const LoginPage: React.FC = function () {
  return (
    <div className="login-base">
      <UserLoginFormProvider>
        <Routes>
          <Route path="" element={<LoginLanding />} />
          <Route
            path={RouteConstants.LOGIN_RESET_PASSWORD}
            element={<LoginResetPassword />}
          />
        </Routes>
      </UserLoginFormProvider>
    </div>
  );
};

export default LoginPage;
