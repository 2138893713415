import { CHALLENGE_NAME, SESSION } from 'constants/auth.constants';
import useAuth from 'hooks/useAuth';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import info from '../../../assets/info.svg';
import {
  LoginData,
  passwordRegExp,
} from '../../../login-context/loginConstants';
import UserLoginFormContext from '../../../login-context/UserLoginFormContext';
import { ALUMNI, LOGIN } from '../../../routeConstants';
import SlalomTooltip from '../../common/slalom-tooltip/slalom-tooltip';
import { SolidButton } from '../../common/solid-button/solid-button';
import { Password, PasswordStatus } from '../../password/password';
import './login-reset-password.scss';

const resetPasswordTooltipText =
  'At least 8 characters in length \n At least 1 uppercase letter \n' +
  'At least 1 lowercase letter \n At least 1 number \n At least 1 special character';

const LoginResetPassword: React.FC = function () {
  const { loginData: state, updateLoginData: dispatch } =
    useContext(UserLoginFormContext);
  const [loginData, setLoginData] = useState<LoginData>(state);
  const navigate: NavigateFunction = useNavigate();
  const { resetPassword } = useAuth();
  // calls reset password api and directs to alumni upon successful password reset
  const resetPasswordNavigate = async () => {
    const session: string | null = sessionStorage.getItem(SESSION);
    const challengeName: string | null = sessionStorage.getItem(CHALLENGE_NAME);

    if (session && challengeName) {
      if (
        await resetPassword(
          loginData.email,
          loginData.confirmPassword,
          challengeName,
          session
        )
      ) {
        navigate(ALUMNI);
      } else {
        navigate(LOGIN);
      }
    }
  };

  const handleChange =
    (prop: keyof LoginData) => (event: ChangeEvent<HTMLInputElement>) => {
      setLoginData({ ...loginData, [prop]: event.target.value });
    };

  const [resetStatus, setResetStatus] = useState(PasswordStatus.INITIAL);
  const [confirmStatus, setConfirmStatus] = useState(PasswordStatus.INITIAL);
  const validReset: boolean =
    passwordRegExp.test(loginData.resetPassword) &&
    passwordRegExp.test(loginData.confirmPassword) &&
    loginData.resetPassword === loginData.confirmPassword;

  useEffect(() => {
    if (loginData.resetPassword === '') {
      setResetStatus(PasswordStatus.INITIAL);
    }
    if (passwordRegExp.test(loginData.resetPassword)) {
      setResetStatus(PasswordStatus.VALID);
    } else if (!(resetStatus === PasswordStatus.ERROR)) {
      setResetStatus(PasswordStatus.INITIAL);
    }
    if (loginData.confirmPassword === '') {
      setConfirmStatus(PasswordStatus.INITIAL);
    }

    if (validReset) {
      setConfirmStatus(PasswordStatus.VALID);
    } else {
      setConfirmStatus(PasswordStatus.INITIAL);
    }
  }, [loginData.resetPassword, loginData.confirmPassword]);

  const handleClickAway = () => {
    if (loginData.resetPassword === '') {
      setResetStatus(PasswordStatus.INITIAL);
    } else if (passwordRegExp.test(loginData.resetPassword)) {
      setResetStatus(PasswordStatus.VALID);
    } else {
      setResetStatus(PasswordStatus.ERROR);
    }
  };

  return (
    <div className="login-info">
      <section className="info-box">
        <h3 className="title">Please reset your password</h3>
        <div className="resetPassword">
          <Password
            onBlur={handleClickAway}
            onChange={handleChange}
            field="resetPassword"
            password={loginData.resetPassword}
            label="Password*"
            status={resetStatus}
          />
          <div className="password-tooltip">
            <SlalomTooltip
              data-testid="tooltip"
              title={
                <span style={{ whiteSpace: 'pre-line' }}>
                  {resetPasswordTooltipText}
                </span>
              }
              placement="right"
            >
              <img className="info-icon" src={info} alt="" />
            </SlalomTooltip>
          </div>
        </div>
        <Password
          onChange={handleChange}
          field="confirmPassword"
          password={loginData.confirmPassword}
          label="Confirm password*"
          status={confirmStatus}
        />
        <SolidButton
          testId="done-button"
          className="done-button"
          buttonText="Done"
          disabled={!validReset}
          onClick={() => {
            resetPasswordNavigate();
            dispatch(loginData);
          }}
        />
      </section>
    </div>
  );
};

export default LoginResetPassword;
