import { FC } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import video from '../../assets/homepage-hero-v8.mp4';
import { REGISTRATION_BASIC_INFO } from '../../routeConstants';
import { SolidButton } from '../common/solid-button/solid-button';
import './registration-landing.scss';

const RegistrationLanding: FC = function () {
  const navigate: NavigateFunction = useNavigate();
  const navigateToRegistrationBasicInfo = () => {
    navigate(REGISTRATION_BASIC_INFO);
  };

  return (
    <div className="registration-landing">
      <video autoPlay muted loop className="registration-hero-video">
        <source src={video} type="video/mp4" />
      </video>
      <section className="registration-start">
        <h3>Welcome to Slalom Relay, we’re glad you’re here!</h3>
        <p>
          This is dummy text. Something has always existed. According to
          physics, there can never be true physical nothingness—though there can
          be times.
        </p>
        <p>
          When existence resembles nothing, such as a vacuum (the state of
          minimum possible energy) (Phys.org). Creating a space where there are
          no quantum fluctuations requires an enormous amount of energy, and
          there would be a remnant of that energy.
        </p>
        <SolidButton
          className="begin-button"
          buttonText="Begin"
          buttonTheme="primary"
          onClick={navigateToRegistrationBasicInfo}
          isWhiteBackground
        />
      </section>
    </div>
  );
};

export default RegistrationLanding;
