import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { ChangeEvent, FC, useState, KeyboardEvent } from 'react';
import './email-search.scss';

interface EmailSearchProps {
  onSearchClick: () => void;
}

const EmailSearch: FC<EmailSearchProps> = function ({
  onSearchClick,
}: EmailSearchProps) {
  const [email, setEmail] = useState<string>('');
  const [emailSearched, setEmailSearched] = useState<boolean>(false);
  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const clearSearch = () => {
    setEmail('');
    setEmailSearched(false);
  };
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && email && !emailSearched) {
      onSearchClick();
      setEmailSearched(true);
    }
  };
  const iconClear = (
    <IconButton
      className="button-base"
      data-testid="clear-button"
      aria-label="clear searched email"
      disableRipple
      onClick={() => clearSearch()}
      edge="end"
    >
      <ClearRoundedIcon className="clear-icon" />
    </IconButton>
  );
  const iconSearch = (
    <IconButton
      className="button-base"
      data-testid="search-button"
      aria-label="search"
      disableRipple
      onClick={() => {
        if (email) {
          onSearchClick();
          setEmailSearched(true);
        }
      }}
      edge="end"
    >
      <SearchIcon className="search-icon" />
    </IconButton>
  );
  return (
    <div className="email-search-container">
      <FormControl variant="outlined" className="email-search">
        <InputLabel
          htmlFor="email-search"
          className={!emailSearched && email ? 'blurred' : 'label'}
        >
          {!emailSearched && 'Email address'}
        </InputLabel>
        <OutlinedInput
          id="email-search"
          data-testid="email-search"
          className={emailSearched ? 'email-searched' : 'email-search-outline'}
          onChange={onSearchChange}
          value={email}
          onKeyPress={(e) => handleKeyPress(e)}
          readOnly={emailSearched}
          label="Email address"
          endAdornment={
            <InputAdornment position="end">
              {emailSearched ? iconClear : iconSearch}
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};

export default EmailSearch;
